
    import { DeleteSweep, ListAltOutlined, LocalShipping, Person, ReceiptLongOutlined } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { PageLayout } from '../../components'
import { Requests } from '../../services/requests';
    import { StyledDashBoardPharm } from './DashBoardPharm.styled'
    export default function DashBoardPharm() {
        const [pageLoading,setPageLoading] = useState(true);
        const [statData,setStatData] = useState({
            insuranceUser:0,
            batch:0,
            prescription:0,
            expired:0,
            outofStock:0
        });
        useEffect(()=>{
            (async ()=>{
            try{
                setPageLoading(true);
                const batchres = await Requests.getBatchPharm();
                const iuser = await Requests.getInsuranceUser();
                const pres = await Requests.getPrescription();
                 const exp = await Requests.getBatchExpiredPharm();
                const outofstock =  await Requests.getBatchOutOfStockPharm();
                setStatData({
                    insuranceUser:iuser.data.data.length,
                    batch:batchres.data.data.reduce((t,n)=>t+n.quantity,0),
                    prescription:pres.data.data.length,
                    expired:exp.data.data.length,
                    outofStock:outofstock.data.data.length
                })
                setPageLoading(false);
            }catch(e){
                setPageLoading(true);
                const batchres = await Requests.getBatchPharm();
                const iuser = await Requests.getInsuranceUser();
                const pres = await Requests.getPrescription();
                 const exp = await Requests.getBatchExpiredPharm();
                const outofstock =  await Requests.getBatchOutOfStockPharm();
                setStatData({
                    insuranceUser:iuser.data.data.length,
                    batch:batchres.data.data.reduce((t,n)=>t+n.quantity,0),
                    prescription:pres.data.data.length,
                    expired:exp.data.data.length,
                    outofStock:outofstock.data.data.length
                })
                setPageLoading(false);
            }
            })();
        },[])
    return (
        <PageLayout loading={pageLoading} title="DashBoard">
        <StyledDashBoardPharm> 
            <div>
              <div className='card'>
                  <ListAltOutlined/>
                  <div>
                      <h3>Total Batch</h3>
                      <span>{statData.batch}</span>
                  </div>
              </div>
              <div className='card'>
                  <ReceiptLongOutlined/>
                  <div>
                      <h3>Prescription</h3>
                      <span>{statData.prescription}</span>
                  </div>
              </div>
              <div className='card'>
                  <DeleteSweep/>
                  <div>
                      <h3>Expired Medicine</h3>
                      <span>{statData.expired}</span>
                  </div>
              </div>
              <div className='card'>
                  <LocalShipping/>
                  <div>
                      <h3>Out of stock Medicine</h3>
                      <span>{statData.outofStock}</span>
                  </div>
              </div>
             
            </div>
          
        </StyledDashBoardPharm> 
        </PageLayout>
    )
    }
            