
    import styled from "styled-components";
import Themes from "../../style/theme";
    export const StyledDashBoardPharm = styled.div`
     max-width:1000px;
     margin:auto;
  
     & > div{
        display: grid !important;
        grid-template-columns: repeat(auto-fit,minmax(300px ,1fr));
        justify-content:start;
        justify-items:center;
        align-content:stretch;
        align-items:stretch;
        grid-gap: 10px;
        & > *{
            width:100%;
            max-width:500px;
        }
        .card{
            max-width:300px;
            width:100%;
            padding:20px;
            border:5px solid lightgreen;
            border-radius:20px;
            display:flex;
            align-items:center;
            gap:20px;
            & > svg{
                color:${Themes.inputColor};
                transform:scale(2);
            }
            & > div{
                color:${Themes.inputColor};
                margin:20px 0px;
                h3{
                    font-weight:400 ;
                    font-size:1.5em ;
                }
               
            }
        }
     }
    `
            