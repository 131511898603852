import React, { useEffect, useState }  from 'react'
import { CustomTable,FilterAndSearch, FormBtn, PageLayout,Toaster } from '../../components'
import { Requests } from '../../services/requests';
import { Edit ,Delete, CheckBox} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
    import { StyledListPendingUsers } from './ListPendingUsers.styled'
import useToast from '../../custome_hook/useToast';
    export default function ListPendingUsers() {
  
        const navigate =    useNavigate();
        const [loading,setLoading] = useState(true);
        const theader = ["Name","Age","Username","Insurance ID","Insurance Name","Phone","Gender","City","Sub City","Woreda","House no","Profile image","Insurance image","Status"]
        const [listData,setListData] = useState([]);
        const [popUpIndex ,setPopUpIndex] = useState(-1);
        const [pageLoading,setPageLoading] = useState(true);
        const [listDatainit,setListDatainit] = useState([]);
        const [toastMes,setToastMes] = useToast();
        const searchBy  = [{key:"name",name:"Name"},{key:"phone",name:"Phone"},{key:"inid",name:"Insurance Id"}];
        const filters = [{key:"isActive",name:"Status",list:["Active","Inactive"]}];
        const handleSearch = (searched)=>{
            setListData(searched)
        }
        const actions = [
            {
                icon:CheckBox,handler:async (ind)=>{
                    const id = listData[ind].id;
                    console.log(listData[ind]);
                    try{    
                        setPageLoading(true);
                       setListData(listData.filter((l,i)=>i!=ind));
                       await Requests.acceptPendingInsuranceUser({id});
                       setListDatainit(listData.filter((l,i)=>i!=ind));
                       setToastMes({message:"User accepted successfully"});
                       setPageLoading(false);
                    }catch(e){
                        setToastMes({message:e.response.data.message,isError:true});
                        console.log(e);
                        setPageLoading(false);
                    }
                }
            },
            {
            icon:Delete,handler:async (ind)=>{
                if(listData[ind].isHQ) return   setToastMes({message:"Head Quarter can't be deleted",isError:true});
                console.log(listData[ind]);
                //setPopUpIndex(ind);
                const id = listData[ind].id;
                try{    
                   setListData(listDatainit.filter((l,i)=>i!=ind));
                    await Requests.deleteInsuranceUser(id);
                   setListDatainit(listData.filter((l,i)=>i!=ind));
                   setToastMes({message:"Deleted Successfully",isError:false});
                }catch(e){
                    setToastMes({message:e.response.data.message,isError:true});
                    console.log(e);
                }
            }
        }
    ];
    useEffect(()=>{
        setListData(listDatainit);
    },[listDatainit])
    useEffect(()=>{
        (async ()=>{
            try{
                setPageLoading(true);
                const res = await Requests.getPendingInsuranceUser();
                console.log(res);
                if(res.data.success){
                setListDatainit([
                        ...res.data.data.map(r=>{return {
                                 id:r._id,
                                 name:r.name,
                                 age:r.age,
                                 uname:r.uname,
                                 inid:r.insurance_id,
                                 inname:r.insurance_name,
                                 phone:r.phone,
                                 gender:r.gender,
                                 city:r.city,
                                 subcity:r.sub_city,
                                 woreda:r.woreda,
                                 houseno:r.house_no,
                                 img:r.image.url,
                                 img2:r.insurance_image.url,
                                 isActive: r.isActive ? "Active" : "Inactive"
                        }})
                    ])
                }
                setPageLoading(false);
                setLoading(false);
            }catch(e){
                setPageLoading(false);
                console.log(e);
                if(e.response)
                setToastMes({message:e.response.data.message,isError:true})
                else setToastMes({message:e.toString(),isError:true})
                setLoading(false)
            }
        })();
    },[]);
    useEffect(()=>{
        setTimeout(()=>{
          setToastMes("")
         },5000);
       },[toastMes])
    return (
        <PageLayout loading={pageLoading} title='Applied Insured Users'>  
        <Toaster message={toastMes.message} isError={toastMes.isError} />
        <StyledListPendingUsers> 
        { 
        popUpIndex < 0 ? null :  
        <div className='pop-up' onClick={()=> setPopUpIndex(-1)}  > 
          <div>
            <textarea placeholder='Enter message'>

            </textarea>
            <FormBtn isFullWidth={false} disabled={loading} label="Add" />
          </div>
        </div>
        }
        <FilterAndSearch searchHandler={handleSearch} data={listDatainit.map(l=>{const {isHQ,...rest} = l;return rest})} searchBy={searchBy} filters={filters} />
              <CustomTable loading={loading} theader={theader} actions={actions} data={listData.map(l=>{const {id,isHQ,...rest} = l;return rest})}/>
        </StyledListPendingUsers> 
        </PageLayout> 
    )
    }
            