
  import React, { useEffect, useState }  from 'react'
    import {FormBtn,CustomTable,FilterAndSearch, PageLayout,Toaster } from '../../components'
    import { useNavigate } from 'react-router-dom';
    import { Requests } from '../../services/requests';
    import { StyledAddNotify } from './AddNotify.styled'
    export default function AddNotify() {
        const [message,setMessage] = useState("");
        const [toastMes,setToastMes] = useState({message:"",isError:false});
        const [loading,setLoading] = useState(false);
        const navigate = useNavigate();
        const handleSubmit = async (e)=>{
            e.preventDefault();
            if(message.trim()===""){
                setToastMes({message:"Empty message not allowed",isError:true})
                return false;
            }
              try{ 
                setLoading(true)
                console.log(message);
                 const res = await Requests.sendNotify({
                  message:message
                });
                 if(res.data.success)
                 navigate("/")
                else setLoading(false);
              }catch(e){
                setToastMes({message:e.toString(),isError:true});
                setLoading(false);
              }
         };
        return (
        <PageLayout loading={false} title='Send Notification'>  
        <Toaster message={toastMes.message} isError={toastMes.isError} />  
        <StyledAddNotify> 
            <form onSubmit={handleSubmit}>
                <textarea onChange={(e)=>setMessage(e.target.value)} placeholder='Enter your message'></textarea>
                <FormBtn isFullWidth={false} disabled={loading} label="Send" />
            </form>
        </StyledAddNotify> 
        </PageLayout>
    )
    }
            