
    import React, { useEffect, useState } from 'react'
    import { StyledDashboardHeader } from './DashboardHeader.styled'
    import {Menu,NotificationsOutlined,LocalShipping,WarningAmber,PersonAddAlt,Person,DeleteSweep,Edit,Logout, NotificationAddOutlined, SendOutlined, PeopleAltTwoTone, MedicalInformationSharp, MedicalInformationTwoTone} from "@mui/icons-material";
    import {useDispatch,useSelector} from "react-redux";
    import { Link, useNavigate } from 'react-router-dom'
    import {setUser} from "../../redux/reducers/user.reducer";
import { Requests } from '../../services/requests';
    export default function DashboardHeader({handleExpanded}) {
      const [showAccountDropdown,setShowAccountDropDown] = useState(false);
      const [expired,setExpired] = useState(0);
      const [stockOut,setStockOut] = useState(0);
      const [pendingMedcineAdmin,setPendingMedcineAdmin] = useState(0);
      const [pendingMedcinePharm,setPendingMedcinePharm] = useState(0);
      const [pendingUser,setPendingUser] = useState(0);
      const [notify,setNotify] = useState(0);
      const user  = useSelector((state) => state.user.user);
      const dispatch= useDispatch();
      const navigate = useNavigate();
      const onClickOutside = (e) => {
        if(e.target)
        if (!e.target.className.includes("click-protected")) {
         setShowAccountDropDown(false)
        }
      };
useEffect(()=>{
  (async()=>{
    if(!user.isAdmin){
      try{
            setInterval(async ()=>{
              const resst = await Requests.getBatchOutOfStockPharm();
              const resexp = await Requests.getBatchExpiredPharm();
              const respend = await Requests.getPendingPharm();
              setStockOut(resst.data.data.length);
              setExpired(resexp.data.data.length);
              setPendingMedcinePharm(respend.data.data.length);
            },5000)
      }catch(e){
            console.log(e);
      }
    }else{
    
          try{
            setInterval(async ()=>{
              const resst = await Requests.getBatchOutOfStockAdmin();
              const resexp = await Requests.getBatchExpiredAdmin();
              const resnot  = await Requests.getNotify();
              const resiu  = await Requests.getPendingInsuranceUser();
              const respend  = await Requests.getPending();
              setStockOut(resst.data.data.length);
              setExpired(resexp.data.data.length);
              setNotify(resnot.data.data.length);
              setPendingMedcineAdmin(respend.data.data.length);
              setPendingUser(resiu.data.data.length);
            },5000)
           
          }catch(e){
            console.log("failed")
            console.log(e);
         }
    }
  })();
 
  window.addEventListener("click", onClickOutside);
  return ()=>{
    window.removeEventListener("click", onClickOutside);
  }
},[]);

    return (
        <StyledDashboardHeader> 
            <Menu onClick={()=>handleExpanded()}/>
           <div className='header-actions-cont'>
                { !user.isAdmin ? <>
                  <div title="notifications" onClick={()=>navigate("/notify")} className='header-actions'>
                    <SendOutlined/>
                  </div>
                  <div title="pending medicine" onClick={()=>navigate("/list_pending")} className='header-actions'>
                    <MedicalInformationTwoTone/>
                    {pendingMedcinePharm > 0 ? <span>{pendingMedcinePharm}</span> : null}
                  </div>
                </>
                  : <>
                  <div title="notifications" onClick={()=>navigate("/notify")} className='header-actions'>
                    <NotificationsOutlined/>
                    {notify > 0 ? <span>{notify}</span> : null}
                  </div>
                  <div title="pending users" onClick={()=>navigate("/list_pending_user")} className='header-actions'>
                    <PeopleAltTwoTone/>
                    {pendingUser > 0 ? <span>{pendingUser}</span> : null}
                  </div>
                  <div title="pending medicine" onClick={()=>navigate("/list_pending")} className='header-actions'>
                    <MedicalInformationSharp/>
                    {pendingMedcineAdmin > 0 ? <span>{pendingMedcineAdmin}</span> : null}
                  </div>
                  </>
        
                }
                  <div title="expired" onClick={()=>navigate("/expired")} className='header-actions'>
                    <DeleteSweep/>
                    {expired > 0 ? <span>{expired}</span> : null}
                  </div>
                  <div title="out of stock" onClick={()=>navigate("/outofstock")} className='header-actions'>
                    <LocalShipping />
                    {stockOut > 0 ? <span>{stockOut}</span> : null}
                  </div>
                  <div className='header-actions'>
                    <PersonAddAlt onClick={()=>setShowAccountDropDown(!showAccountDropdown)} />
                    <div  className={`dropdown-account ${showAccountDropdown ? "show" : ""}`}>
                          <div>
                          <img className='click-protected' src={user.profile ?? "/assets/profile.jpg"} />
                          <h6 className='click-protected'>{user.name}</h6>
                          <span className='click-protected'>{user.username}</span>
                          </div>
                          <div>
                           <div><Link to="/account"><Edit /> <span>Change Password</span></Link></div>
                            <div onClick={()=>dispatch(setUser(null))}><Logout /> <span>Sign out</span></div>
                          </div>
                    </div>
                  </div>
           </div>
        </StyledDashboardHeader> 
    )
    }
            