
  import React,{useState} from 'react'
  import { StyledLogin } from './Login.styled'
  import {FormBtn, FormInput} from "../../components";
  import {useNavigate} from "react-router-dom";
  import {useDispatch} from "react-redux";
  import {setUser} from "../../redux/reducers/user.reducer";
import { Requests } from '../../services/requests';
    export default function Login() {
        const dispath = useDispatch();
        const [username,setUsername] = useState("");
        const [password,setPassword] = useState("");
        const [loading ,setLoading] = useState(false);
        const navigator = useNavigate();
        const [subError ,setSubError] = useState("");
        const handleLogin = async (e)=>{
            e.preventDefault();
            try {
                setLoading(true);
                try{
                 const res = await Requests.login({username,password});
                 const data = await res.json();
                 if(data.success){

                  localStorage.setItem("accessToken",data.accessToken);
                  localStorage.setItem("refreshToken",data.refreshToken)
                   dispath(setUser({
                     username: data.uname,
                     name:data.name,
                     profile:data.profile,
                     isAdmin:data.isAdmin,
                     role:data.role
                   }));
                   navigator("/");
                 }else {
                    setSubError(data.message);
                    setLoading(false);
                 }
                 
                }catch(e){
                  setSubError(e.message);
                  // else setSubError(e.toString());
                   setLoading(false);
                }
           
            } catch (e) {
              setSubError(e.toString());
              setLoading(false);
            }
          }
          const handleEmailInput = (val)=>{
            setUsername(val);
         }
         const handlePasswordInput = (val)=>{
           setPassword(val);
         }
    return (
        <StyledLogin onSubmit={handleLogin}>    
            {/* <img src='assets/kenema_logo.png' /> */}
            <div className='login-container'>
            <span className='login-title'>Sign in</span> 
            {subError.trim() != "" ? <span className='sub-error'>{subError}</span> : null}
            <FormInput required={true} error="" label="Username" type="text" disabled={loading} handleInput={handleEmailInput }  placeholder="Enter your username" />
            <FormInput required={true} error="" label="Password" type="password" disabled={loading} handleInput={handlePasswordInput} placeholder="Enter your password" />
            <FormBtn  disabled={loading} label="Sign in" />
            </div>
        </StyledLogin> 
    )
    }
            