
export const nameFieldValidator = (val)=>{
    let result = {message:"",result:true};
    if(!val.trim().match(/^[A-Z\sa-z]+$/g))
    result = {message:"invalid name",result:false};
    return result;
}

export const takeTimeValidator = (val)=>{
    let result = {message:"",result:true};
    if(!val.trim().match(/^(([0-1]?[0-9]|2[0-3]):[0-5][0-9]\s?(AM|PM))(,([0-1]?[0-9]|2[0-3]):[0-5][0-9]\s?(AM|PM))?$/g))
    result = {message:"Invalid Time",result:false};
    return result;
}
export const numberFieldValidator = (val)=>{
    let result = {message:"",result:true};
    if(!val.trim().match(/^[0-9.]+$/g))
    result = {message:"Invalid number",result:false};
    return result;
}
export const emptyFieldValidator = (val)=>{
    let result = {message:"",result:true};
    if(val !== null &&val.trim() === "")
    result = {message:"empty filled not allowed",result:false};
    return result;
}
export const phoneValidator = (input)=>{
    let result = {message:"",result:true};
    if(!input.trim().split(" ").join("").match(/^\+251\d{9}$/g)) result = {message:"invalid phone number",result:false};
    if(!(input.split("").length === 13)) result = {message:"invalid phone number (Example +251911111111)",result:false};
    return result;
}
export const passwordValidator = (val)=>{
    let result = {message:"",result:true};
    if(!val.trim().match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g))
        result = {message:"password should be 8 characters length and contains , Lower Case Letters, Upper Case Letters, Special Character and Numbers",result:false};
        return result;
}
export default (val,validators)=>{
        let result = {message:"",result:true};
        validators.every(validator => {
             result =  validator(val);
             if(!result.result) return false;
             else return true;
       });
       return result;
}