
    import styled from "styled-components";
import Themes from "../../style/theme";
    export const StyledLandingPage = styled.div`
      header{
        background-color:black;
        height:auto;
        max-height:600px;
        overflow:hidden;
        position:relative;
        img{
            width:100vw;
            opacity:0.3;
            width:100%;
            height:100%;
            object-fit:cover ;
        }
        .header-text{
          position:absolute;
          top:0;
          bottom:0;
          right:0;
          left:0;
          display:flex;
          flex-direction:column;
          justify-content:center ;
          width:100%;
          color:white;
          h1{
            font-weight:600;
          font-size:5vw;
          text-align:center ;
          &:last-child{
            font-weight:300;
            opacity:0.8;
            font-size:4vw;
          }
          }

       
        }
      }
      main{
        display:flex;
        justify-content:space-evenly;
        flex-wrap:wrap;
        & > span{
          display:flex;
          flex-direction:column;
          flex:1;
          max-width:500px;
          min-width:300px ;
          margin:10px;
          box-shadow:0px 0px 3px ${Themes.lightgray};
          padding:20px;
          border-radius:10px;
          h6{
            font-size:1.3em;
            border-bottom:1px solid ${Themes.niceGrey};
            margin-bottom:5px;
            padding-bottom:5px;
            color:${Themes.veryDarkGrey};
          }
          p{
            color:${Themes.veryDarkGrey};
          }
        }
      }
      footer{
        display:flex;
        justify-content:center;
        padding:20px;
        background-color:${Themes.niceGrey};
      }
    `
            