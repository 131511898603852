
    import React from 'react'
    import { StyledRegister } from './Register.styled'
    import {AddInsured} from "../";
import { PageLayout } from '../../components';
    export default function Register() {
    return (

          <StyledRegister>
            <PageLayout loading={false}>
            <AddInsured />
            </PageLayout>
          </StyledRegister>
    )
    }
        