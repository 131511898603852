
    import styled from "styled-components";
import Themes from "../../style/theme";
    export const StyledRegisteredMessage = styled.div`
     display:flex;
     justify-content:center;
     flex-direction:column;
     align-items:center;
     svg{
        color:${Themes.primaryGreen};
        transform:scale(3);
     }
     h1{
        margin-top:50px;
        color:${Themes.fadeBlack};
        font-weight:100;
        font-size:5vw;
     }
    `
            