
    import React, { useEffect, useState } from 'react'
    import { StyledFormInput } from './FormInput.styled'
    import {Visibility,VisibilityOff} from "@mui/icons-material";
    export default function FormInput({disabled,type,required,placeholder,error,handleInput,label}) {
    const isPassword = type.toUpperCase() == "PASSWORD" ? true : false;
    const [isPasswordVisible,setIsPasswordVisible] = useState(false);
    const [typei,setTypei]  = useState(type);
    useEffect(()=>{
       if(isPassword)
       if(isPasswordVisible) setTypei("text")
       else setTypei("password")
    },[isPasswordVisible]);
    return (
        <StyledFormInput> 
             <label>{label}</label>
             <div className='input-cont'>
             <input onBlur={(e)=>e.target.parentElement.classList.remove("focused")} onFocus={(e)=>e.target.parentElement.classList.add("focused")} type={typei} autoComplete="none" placeholder={placeholder} required={required} disabled={disabled} onChange={(e)=>handleInput(e.target.value)} />
             {
                isPassword ? (
                    isPasswordVisible ?  <Visibility onClick={()=>setIsPasswordVisible(false)} /> : <VisibilityOff onClick={()=>setIsPasswordVisible(true)} /> 
                ) : null
               
            }
             </div>
             
     <span>{error} </span>
        </StyledFormInput> 
    )
    }
            