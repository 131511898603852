
    import styled from "styled-components";
import Themes from "../../style/theme";
    export const StyledLandingNavbar = styled.div`
      display:flex;
      justify-content:space-between;
      flex-wrap:wrap ;
      img{
        width:80px;
      }
      & > *{
        margin:10px;
      }
      & > span{
        display:flex;
        align-items:center;
        & > *{
            margin-left:5px;
        }
        h1{
            font-weight:100;
            color:${Themes.fadeBlack};
        }
      }
      & > div{
        display:flex;
        flex:1;
        justify-content:space-evenly;
        align-items:center;
        max-width:400px;
        a{
             
              padding:5px;
              text-decoration:none;
              color:${Themes.fadeBlack};
              transition:opacity 0.5s;
              &:hover{
                 opacity:0.5;
             }
             &.active_nav{
                background-color:${Themes.niceGrey};
             }
        }
      }
    `
            