import React , { useEffect, useState } from 'react'
import { useNavigate,useParams } from 'react-router-dom';
import { Toaster} from '../../components'
import { Requests } from '../../services/requests';
import validator,{nameFieldValidator, numberFieldValidator,emptyFieldValidator, passwordValidator, phoneValidator} from "../../services/validator";
import { CustomeDropdown,FileInput,DynamicInput, FormBtn, GoogleMap, PageLayout } from '../../components'
import { StyledEditInsured } from './EditInsured.styled'
export default function EditInsured() {
    // const {id} = useParams();
    const {id} = useParams();
    const list = ["Active","Inactive"];
    const listGender = ["Male","Female"];
    const [name,setName] = useState({value:"",isValid:"false",error:""});
    const [age,setAge] = useState({value:"",isValid:"false",error:""});
    const [dob,setDob] = useState({value:"",isValid:"false",error:""});
    const [phone,setPhone] = useState({value:"",isValid:"false",error:""});
    const [city,setCity] = useState({value:"",isValid:"false",error:""});
    const [subCity,setSubcity] = useState({value:"",isValid:"false",error:""});
    const [wereda,setWereda] = useState({value:"",isValid:"false",error:""});
    const [houseno,setHouseno] = useState({value:"",isValid:"false",error:""});
    const [insuranceId,setInsuranceId] = useState({value:"",isValid:"false",error:""});
    const [insuranceName,setInsuranceName] = useState({value:"",isValid:"false",error:""});
    const [username,setUsername] = useState({value:"",isValid:"false",error:""});
    const [password,setPassword] = useState({value:"",isValid:"false",error:""});
    const [confirmPassword,setConfirmPassword] = useState({value:"",isValid:"false",error:""});
    const [selected,setSelected] = useState(null);
    const [pageLoading,setPageLoading] = useState(true);
    const [selectedGender,setSelectedGender] = useState(null);
    const [selectError,setSelectError] = useState("");
    const [selectErrorGender,setSelectErrorGender] = useState("");
    const [loading ,setLoading] = useState(false);
    const [toastMes,setToastMes] = useState({message:"",isError:false});
    const navigate = useNavigate();
    const [img,setImg] = useState(null);
    const [insuranceImg,setInsuranceImg] = useState(null);
 
    const handleName = (val)=>{
        const {message,result} = validator(val ,[emptyFieldValidator, nameFieldValidator]);
        setName({value:val,isValid:result,error:message});
    }

    const handelAge = (val)=>{
        const {message,result} = validator(val ,[emptyFieldValidator]);
        setAge({value:val,isValid:result,error:message});
    }
    const handleDob = (val)=>{
        const {message,result} = validator(val ,[emptyFieldValidator]);
        setDob({value:val,isValid:result,error:message});
    }

    const handlePhone = (val)=>{
        const {message,result} = validator(val ,[emptyFieldValidator,phoneValidator]);
        setPhone({value:val,isValid:result,error:message});
    }
    

    const handleCity = (val)=>{
        const {message,result} = validator(val ,[emptyFieldValidator, nameFieldValidator]);
        setCity({value:val,isValid:result,error:message});
    }

    const handleSubcity = (val)=>{
        const {message,result} = validator(val ,[emptyFieldValidator, nameFieldValidator]);
        setSubcity({value:val,isValid:result,error:message});
    }

    const handleWereda = (val)=>{
        const {message,result} = validator(val ,[emptyFieldValidator]);
        setWereda({value:val,isValid:result,error:message});
    }

    const handleHouseno = (val)=>{
        const {message,result} = validator(val ,[emptyFieldValidator]);
        setHouseno({value:val,isValid:result,error:message});
    }

    const handleInsuranceId = (val)=>{
        const {message,result} = validator(val ,[emptyFieldValidator]);
        setInsuranceId({value:val,isValid:result,error:message});
    }

    const handleInsuranceName = (val)=>{
        const {message,result} = validator(val ,[emptyFieldValidator, nameFieldValidator]);
        setInsuranceName({value:val,isValid:result,error:message});
    }

    const handleUsername = (val)=>{
        const {message,result} = validator(val ,[emptyFieldValidator]);
        setUsername({value:val,isValid:result,error:message});
    }

    const handlePassword = (val)=>{
        const {message,result} = validator(val ,[]);
        setPassword({value:val,isValid:result,error:message});
    }
    const handleConfirmPassword = (val)=>{
        const {message,result} = validator(val ,[]);
        if(val===password.value)  setConfirmPassword({value:val,isValid:result,error:message});
        else setConfirmPassword({value:val,isValid:false,error:"Password mismatch"});
     }
    const handleSelected = (ind) =>{
        setSelected(ind);
        setSelectError("");
   }
   const handleSelectedGender = (ind) =>{
    setSelectedGender(ind);
    setSelectErrorGender("");
}
    const handleFileChange = (data)=>{
        setImg(data);
  }
  const handleInsuranceFileChange = (data)=>{
    setInsuranceImg(data);
}
  useEffect(()=>{
    setTimeout(()=>{
      setToastMes("")
     },5000);
   },[toastMes]);
   const handleSubmit = async (e)=>{
      e.preventDefault();
      if(name.isValid&&
        age.isValid&&
        dob.isValid&&
        phone.isValid&&
        city.isValid&&
        subCity.isValid&&
        wereda.isValid&&
        houseno.isValid&&
        insuranceId.isValid&&
        insuranceName.isValid&&
        username.isValid&&
        password.isValid&&
        confirmPassword.isValid&&
        selected!=null&&
        selectedGender!==null
        ){
        setLoading(true);
        try{ 
           const res = await Requests.updateInsuranceUser(id,{
            name:name.value,
            age:age.value,
            gender: listGender[selectedGender],
            dob: dob.value,
            phone: phone.value,
            city: city.value,
            sub_city: subCity.value,
            woreda: wereda.value,
            house_no: houseno.value,
            image:img,
            insurance_id: insuranceId.value,
            insurance_name: insuranceName.value,
            insurance_image:insuranceImg,
            username:username.value,
            password: password.value,
            isActive:selected === 0 ? true : false 
          });
           if(res.data.success)
            navigate("/list_insured",{state:{message:"User updated successfully",isError:false}})
          else setLoading(false);
        }catch(e){
          setToastMes({message:e.response.data.message,isError:true});
          setLoading(false);
        }
        setLoading(false);
      }else{
        setPageLoading(false);
        if(selected == null) setSelectError("Select is required");
        if(selectedGender == null) setSelectErrorGender("Select is required");
        handleName(name.value);
        handelAge(age.value);
        handleDob(dob.value);
        handlePhone(phone.value);
        handleCity(city.value);
        handleSubcity(subCity.value);
        handleWereda(wereda.value);
        handleHouseno(houseno.value)
        handleInsuranceId(insuranceId.value);
        handleInsuranceName(insuranceName.value);
        handleUsername(username.value);
        handlePassword(password.value)
        handleConfirmPassword(confirmPassword.value);
      }
   };
   useEffect(()=>{
    (async ()=>{
        try{
            setPageLoading(true);
            const res = await Requests.getInsuranceUserById(id);
            const data  = res.data.data;
            setName({value:data.name,isValid:true,error:""});
            setAge({value:data.age,isValid:true,error:""});
            setDob({value:data.dob,isValid:true,error:""});
            setPhone({value:data.phone,isValid:true,error:""});
            setCity({value:data.city,isValid:true,error:""});
            setSubcity({value:data.sub_city,isValid:true,error:""});
            setWereda({value:data.sub_city,isValid:true,error:""});
            setHouseno({value:data.house_no,isValid:true,error:""});
            setInsuranceId({value:data.insurance_id,isValid:true,error:""});
            setInsuranceName({value:data.insurance_name,isValid:true,error:""});
            setUsername({value:data.uname,isValid:true,error:""});
            setSelectedGender(data.gender === listGender[0] ? 0 : 1);
            setSelected(data.isActive ? 0 : 1)
            setImg(data.image.url);
            setInsuranceImg(data.insurance_image.url) 
            setPageLoading(false);
        }catch(e){
            setPageLoading(false);
        }
       
    })();
},[]);
return (
    <PageLayout loading={pageLoading} title='Edit Insured User'>
         <Toaster message={toastMes.message} isError={toastMes.isError} />
    <StyledEditInsured> 
    <form onSubmit={handleSubmit}> 
               <div>
                <DynamicInput value={name.value} label="Full Name"  type="text" required={true} placeholder="Enter fullname" disabled={loading} error={name.error} handleInput={handleName}/>
                <DynamicInput value={age.value} label="Age"  type="number" required={true} placeholder="Enter age" disabled={loading} error={age.error} handleInput={handelAge}/>
                <DynamicInput value={dob.value} label="Date of Birth"  type="date" required={true} placeholder="Enter date of birth" disabled={loading} error={dob.error} handleInput={handleDob}/>
                <CustomeDropdown label='Gender' error={selectErrorGender} selected={selectedGender} placeholder="Select.." list={listGender} handleSelect={handleSelectedGender} />
                <DynamicInput value={phone.value} label="Phone"  type="text" required={true} placeholder="Enter phone" disabled={loading} error={phone.error} handleInput={handlePhone}/>
                <DynamicInput value={city.value} label="City"  type="text" required={true} placeholder="Enter city" disabled={loading} error={city.error} handleInput={handleCity}/>
                <DynamicInput value={subCity.value} label="Subcity"  type="text" required={true} placeholder="Enter subcity" disabled={loading} error={subCity.error} handleInput={handleSubcity}/> 
                <DynamicInput value={wereda.value} label="Wereda"  type="text" required={true} placeholder="Enter wereda" disabled={loading} error={wereda.error} handleInput={handleWereda}/>
                <DynamicInput value={houseno.value} label="Houseno"  type="number" required={true} placeholder="Enter houseno" disabled={loading} error={houseno.error} handleInput={handleHouseno}/>
                <DynamicInput value={insuranceId.value} label="Insurance ID"  type="Insurance ID" required={true} placeholder="Enter insurance id" disabled={loading} error={insuranceId.error} handleInput={handleInsuranceId}/>
                <DynamicInput value={insuranceName.value} label="Insurance Name"  type="text" required={true} placeholder="Enter insurance name" disabled={loading} error={insuranceName.error} handleInput={handleInsuranceName}/> 
                <DynamicInput value={username.value} label="Username"  type="text" required={true} placeholder="Enter username" disabled={loading} error={username.error} handleInput={handleUsername}/>
                <DynamicInput value={password.value} label="Password"  type="password" required={false} placeholder="Enter password" disabled={loading} error={password.error} handleInput={handlePassword}/>
                <DynamicInput value={confirmPassword.value} label="Confirm Password"  type="password" required={false} placeholder="Enter password again" disabled={loading} error={confirmPassword.error} handleInput={handleConfirmPassword}/>
               <CustomeDropdown label='Status' error={selectError} selected={selected} placeholder="Select.." list={list} handleSelect={handleSelected} />
               <FileInput required={false} label="Upload profile" src={img} handleChange={handleFileChange} />
               <FileInput required={false} label="Upload Insurance" src={insuranceImg} handleChange={handleInsuranceFileChange} />
             </div>
                <FormBtn isFullWidth={false} disabled={loading} label="Update" />
            </form>
    </StyledEditInsured> 
    </PageLayout>
)
}
            