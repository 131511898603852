import React, { useEffect, useState }  from 'react'
import { CustomTable,FilterAndSearch, PageLayout,Toaster } from '../../components'
import { Requests } from '../../services/requests';
import { Edit ,Delete} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
    import { StyledListInsured } from './ListInsured.styled'
import useToast from '../../custome_hook/useToast';
    export default function ListInsured() {

          const navigate =    useNavigate();
        const [loading,setLoading] = useState(true);
        const theader = ["Name","Username","Insurance ID","Insurance Name","Phone","Gender","City","Sub City","Woreda","House no","Status"]
        const [listData,setListData] = useState([]);
        const [pageLoading,setPageLoading] = useState(true);
        const [listDatainit,setListDatainit] = useState([]);
        const [toastMes,setToastMes] = useToast();
        const searchBy  = [{key:"name",name:"Name"},{key:"phone",name:"Phone"},{key:"inid",name:"Insurance Id"}];
        const filters = [{key:"isActive",name:"Status",list:["Active","Inactive"]}];
        const handleSearch = (searched)=>{
            setListData(searched)
        }
        const actions = [
            {
                icon:Edit,handler:(ind)=>{
                navigate(`/edit_insured/${listData[ind].id}`)
                }
            },
            {
            icon:Delete,handler:async (ind)=>{
                if(listDatainit[ind].isHQ) return   setToastMes({message:"Head Quarter can't be deleted",isError:true});
                const id = listData[ind].id;
                try{    
                   setListData(listDatainit.filter((l,i)=>i!=ind));
                    await Requests.deleteInsuranceUser(id);
                   setListDatainit(listDatainit.filter((l,i)=>i!=ind));
                   setToastMes({message:"Deleted Successfully",isError:false});
                }catch(e){
                    setToastMes({message:e.response.data.message,isError:true});
                    console.log(e);
                }
            }
        }
    ];
    useEffect(()=>{
        setListData(listDatainit);
    },[listDatainit])
    useEffect(()=>{
        (async ()=>{
            try{
                setPageLoading(true);
                const res = await Requests.getInsuranceUser();
                if(res.data.success){
                setListDatainit([
                        ...res.data.data.map(r=>{return {
                                 id:r._id,
                                 name:r.name,
                                 uname:r.uname,
                                 inid:r.insurance_id,
                                 inname:r.insurance_name,
                                 phone:r.phone,
                                 gender:r.gender,
                                 city:r.city,
                                 subcity:r.sub_city,
                                 woreda:r.woreda,
                                 houseno:r.house_no,
                                 isActive: r.isActive ? "Active" : "Inactive"
                        }})
                    ])
                }
                setPageLoading(false);
                setLoading(false);
            }catch(e){
                setPageLoading(false);
                console.log(e);
                if(e.response)
                setToastMes({message:e.response.data.message,isError:true})
                else setToastMes({message:e.toString(),isError:true})
                setLoading(false)
            }
        })();
    },[]);
    useEffect(()=>{
        setTimeout(()=>{
          setToastMes("")
         },5000);
       },[toastMes])
    return (
        <PageLayout loading={pageLoading} title='Insured User List'>  
        <Toaster message={toastMes.message} isError={toastMes.isError} />  
        <StyledListInsured> 
        <FilterAndSearch searchHandler={handleSearch} data={listDatainit.map(l=>{const {isHQ,...rest} = l;return rest})} searchBy={searchBy} filters={filters} />
              <CustomTable loading={loading} theader={theader} actions={actions} data={listData.map(l=>{const {id,isHQ,...rest} = l;return rest})}/>
        </StyledListInsured> 
      </PageLayout>  
    )
    }
            