import styled from "styled-components";
import Themes from "../../style/theme";
    export const StyledLogin = styled.form`
     width:100%;
     min-height:100vh;
     overflow:auto;
     position:relative;
     box-sizing:border-box ;
     z-index:1;
     display:flex;
     justify-content:center;
     align-items:center ;
     flex-direction:column ;
     & > *{
      zoom:0.8;
     }
     & > img{
        width:100%;
        max-width:150px;
        margin:10px;
        opacity:0.8;
     }
     h1{
        box-sizing:border-box;
     }
     &::before{
      content:"";
      position:absolute;
      top:0;
      left:0;
      bottom:0;
      right:0;
      background-image:url("./assets/login_bg.webp");
      background-size:auto 100%;
      background-attachment: fixed;
      filter:blur(0.5rem) ;
      z-index:-1;
     }
     .login-container{
       background-color:${Themes.white};
       padding:20px;
       border-radius:10px;
       box-shadow:0 6px 12px rgb(147 159 171);
       width:95%;
       max-width:450px;
       display:flex;
       flex-direction:column;
       align-items:center ;
       box-sizing:border-box;
       & > *{
        max-width:350px;
        box-sizing:border-box;
       }
     }
     .login-title{
        font-size:1.3em;
        font-weight:500;
        margin:30px 0;
     }
     .login-container > label{
        margin-right:auto;
        color:${Themes.fadeBlack}
     }
     .sub-error{
        width:80%;
        color:${Themes.fontRed};
        background-color:${Themes.fadeRed} ;
        padding:10px;
        border-radius:5px;
     }
    `
            