import React, { useEffect, useState }  from 'react'
import { CustomTable,FilterAndSearch, PageLayout,Toaster } from '../../components'
import { Requests } from '../../services/requests';
import { Edit ,Delete, Visibility, MedicalInformation} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
    import { StyledListBatch } from './ListBatch.styled'
import { batch } from 'react-redux';
import useToast from '../../custome_hook/useToast';
    export default function ListBatch() {
        const navigate =    useNavigate();
        const [loading,setLoading] = useState(true);
        const theader = ["Medicine","No of Medicine"]
        const [listData,setListData] = useState([]);
        const [listDatainit,setListDatainit] = useState([]);
        const [toastMes,setToastMes] = useToast();
        const [popUpIndex ,setPopUpIndex] = useState(-1);
        const [pageLoading,setPageLoading] = useState(true);
        const [dat,setDat] = useState([])
        const searchBy  = [{key:"batch",name:"Medicine Name"}];
        const filters = [];
        const headerActions = [
            {icon:MedicalInformation,name:"distribute medicines",handler:async ()=>{
               setPageLoading(true);
               try{
                  await Requests.distMedicine();
                  navigate("/list_pending",{state:{message:"Medicines Distributed",isError:false}});
               }catch(e){
                   setPageLoading(false);
                   console.log(e);
                      setToastMes({message:e.response.data.message,isError:true}) 
               }
               setPageLoading(false);
            }}
       ];
        const handleSearch = (searched)=>{
            setListData(searched)
        }
        const actions = [
            {
                icon:Visibility,
                handler:async (ind)=>{
                   setPopUpIndex(ind)
                }
            },
            {
            icon:Delete,handler:async (ind)=>{
                if(listData[ind].isHQ) return   setToastMes({message:"Head Quarter can't be deleted",isError:true});
                try{    
                    setListData(listData.filter((l,i)=>i!=ind));
                    await Requests.deleteBatch(listData[ind].id);
                    setListData(listData.filter((l,i)=>i!=ind));
                    setToastMes({message:"Deleted Successfully",isError:false});
                }catch(e){
                    setToastMes({message:e.response.data.message,isError:true});
                    console.log(e);
                }
            }
        }
    ];
    useEffect(()=>{
        (async ()=>{
            try{
                setPageLoading(true);
                const res = await Requests.getBatch();
                const res2 = await Requests.getMedcine();
                if(res.data.success){
                    const dat = res.data.data.map(r=>{return {
                        id:r.medicine_id,
                        medicine:res2.data.data.find(d=>d._id == r.medicine_id).name+", "+res2.data.data.find(d=>d._id == r.medicine_id).generic_name,
                        quantity: r.quantity,
                        date_expire:r.date_expire,
                        date_mfg: r.date_mfg,
                    }});
                    console.log(dat)
                    setDat([...dat]);
             
                }
                setLoading(false);
                setPageLoading(false);
            }catch(e){
                console.log(e);
                if(e.response)
                setToastMes({message:e.response.data.message,isError:true})
                else   setToastMes({message:e.toString(),isError:true})
                setLoading(false)
                setPageLoading(false)
            }
        })();
    },[]);
    useEffect(()=>{
        setTimeout(()=>{
          setToastMes("")
         },5000);
       },[toastMes])
       useEffect(()=>{
        setListData(listDatainit);
    },[listDatainit])
       useEffect(()=>{
        const batches = dat.map(d=>{return {id:d.id,med:d.medicine}}).filter((c, index,arr) => {
            return arr.map(d=>{return d.id}).indexOf(c.id) === index;
        });
    setListDatainit([
        ...batches.map(b=>{
            return{
             id:b.id,
             batch:b.med,
             noOfMed:dat.filter(d=>d.id==b.id).reduce((tot,num)=>tot+num.quantity,0)
           }})
        ])
       },[dat])
    return (
        <PageLayout actions={dat.length > 0 ? headerActions : []} loading={pageLoading} title='Batch List'>  
       
        <Toaster message={toastMes.message} isError={toastMes.isError} />  
        <StyledListBatch> 
        { 
        popUpIndex < 0 ? null :  
        <div className='pop-up' onClick={()=> setPopUpIndex(-1)}  > 
          <div>
             <CustomTable   noAction={true} theader={["Medcine","Quantity","Date of Expired","Date of Manufacture"]} actions={actions} data={ dat.filter(d => d.medicine===listData[popUpIndex].batch)}/>
          </div>
        </div>
        }
        <FilterAndSearch searchHandler={handleSearch} data={listDatainit.map(l=>{const {isHQ,...rest} = l;return rest})} searchBy={searchBy} filters={filters} />
        <CustomTable loading={loading} theader={theader} actions={actions} data={listData.map(l=>{const {id,isHQ,...rest} = l;return rest})}/>
        </StyledListBatch> 
        </PageLayout>
    )
    }
            