
    import React from 'react'
    import { StyledLandingPage } from './LandingPage.styled'
    export default function LandingPage() {
    return (
        <StyledLandingPage> 
           <header>
              <img src="/assets/login_bg.webp" />
              <div className='header-text'>
              <h1>Kenema Pharmacy</h1>
              <h1>Your Health is Our Top Priority</h1>
              </div>
           </header>
           <br/><br/>
           <main>
            <span>

            <h6>Who are we?</h6>
             <p>Kenema Pharmacy Enterprise is Nonprofit organization under Addis Ababa Health Bureau that established in 1975 E.C</p>
            </span>
            <span>
            <h6>Mission</h6>
            <p>Provide within the available resource's good quality medcines and medical supplies that are safe,effective and accessible to the Addis Ababa cites residents at affordable price.</p>
            </span>
          
           </main>
           <br/><br/>
           <footer>
            <span>&copy;copyright 2022 kenema Pharmacy</span>
           </footer>
        </StyledLandingPage> 
    )
    }
            