import React from 'react'
import { useState } from 'react';
    import { StyledCustomTable } from './CustomTable.styled'
    export default function CustomTable({theader=[],actions = [],noAction=false ,data = [],loading = false}) {
    const limit = 10;
    const [page,setPage] = useState(1);
    const [popUpIndex ,setPopUpIndex] = useState(-1);
    const [img,setImg] = useState("");
    const getPaginated = (data)=>{
       const starting = (page*limit)-limit;
       return data.slice(starting < 0 ? 0 : starting,starting+limit)
    };
    return (
        <StyledCustomTable onClick={(e)=>e.stopPropagation()}> 
               { 
        popUpIndex < 0 ? null :  
        <div className='pop-up' onClick={()=> setPopUpIndex(-1)}  > 
          <div>
             <img src={img} />
          </div>
        </div>
        }
           <table>
                <thead>
                    {theader.map(th=><td>{th}</td>)}
                    {noAction ? null : <td>Actions</td>}
                </thead>
                <tbody>
                    {getPaginated(data).map((d,i)=><tr>
                        {Object.keys(d).filter(d=>d!=="id").map(k=>
                        <td>{k.match("img") ? <img onClick={()=>{setImg(d[k]);setPopUpIndex(1)}} style={{margin:"auto",display:"block",objectFit:"cover"}} width="100%" height="50" src={d[k]} /> : d[k]}</td>
                        )}
                        {noAction ? null : <td>{actions.map(a=><a.icon onClick={()=>a.handler((page-1)*limit+i)}/>)}</td>}
                    </tr>)}
                </tbody>
            </table>
            <div className='pag-cont'>
                <span>{limit} results maximum and {data.length} total data</span> {
        [...(new Array(Math.ceil(data.length/limit)))].map((d,i)=><span className={i==page-1 ? "pag-active" : ""} onClick={()=>setPage(i+1)}>{i+1}</span>)
                }</div>
            {}
            {loading ? <center>Loading...</center> : data.length == 0 ? <center>No data found</center> : null  }
        </StyledCustomTable> 
    )
    }
            