import React,{useRef} from 'react'
import FormBtn from '../FormBtn/FormBtn.component';
    import { StyledFileInput } from './FileInput.styled'
    export default function FileInput({handleChange,src = null,label="Upload",required = true,error=""}) {
        const doc  = useRef(null);
        const handleFileChange = (e)=>{
            var reader = new FileReader();
            reader.onload = function(){
               handleChange(reader.result)
            }
            reader.readAsDataURL(e.target.files[0]);
          }
    return (
        <StyledFileInput> 
            <span>{label}</span>
            <FormBtn label={"upload"}  onClick={()=>doc.current.click()} type="button" disabled={false} isFullWidth={false} />
           <input required={required} ref={doc} hidden onChange={handleFileChange} type="file" />
           {src && <><span>Preview:</span>
                 <img src={src} /></>}
           <span className='error'>{error}</span>
        </StyledFileInput> 
    )
    }
            