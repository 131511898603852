
    import styled from "styled-components";
import Themes from "../../style/theme";
    export const StyledCustomeDropdown = styled.div`
       display: flex;
   flex-direction: column;
   margin-bottom: 10px;
   & > div{
    display:flex;
    flex-direction: column;
    gap:5px;
    align-items:stretch;
    & > span{
      display: flex;
    }
   }
   h5,span{
    font-family:'Nunito Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
    font-size:18px;
    padding:5px;
   }
   h5{
    color:${Themes.darkGrey};
    font-weight:100;
   }
   .select-cont{
    span{
        color:black;
    }
    font-family:'Nunito Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
       outline: 0;
       border:1px solid black;
       border-radius: 5px;
       color:${Themes.fadeBlack};
       font-size: 14px;
       flex-grow:1;
       border: 1px solid #e4e5e7;
       padding:6 12;
       .selected{
        padding: 12px 20px;
         color:${Themes.inputColor};
        cursor: pointer;
          display:flex;
          justify-content:space-between;
          align-items:center;
          img{
            filter:invert();
            width:20px;
            opacity:0.5;
            margin-left:5px;
            &.droped{
                transform:rotate(180deg);
            }
          }
       }
       position:relative;
       .dropdown-cont{
         position:absolute;
         border:1px solid ${Themes.lightgray};
         width:100%;
         display:flex;
         flex-direction:column;
         background-color:${Themes.white} ;
         z-index:999;
         input{
            padding:7px;
            margin:10px;
            font-size:1em;
            box-sizing:border-box;
            border-radius:5px;
            outline:0;
            border:1px solid ${Themes.inputBg};
         }
         ul{
            list-style-type:none;
            margin:0;
            padding:0;
            display:flex;
            flex-direction:column;
            max-height:150px;
            overflow:auto;
            
            li{
                font-family: 'Nunito Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
                  padding:10px;
                  &.active{
                    background-color:${Themes.inputBg} !important;
                    color:${Themes.fadeBlack} !important;
                    cursor:default !important;
                  }
                  &:hover{
                    background-color: #5897fb;
                    cursor: pointer;
                    color:${Themes.white};
                    
                  }
            }
         }
       }
       &.error{
        box-shadow:0px 0px 3px ${Themes.redLight};
       }

   }
   span{
      padding:0;
      height:40px;
       font-size: 0.9rem;
       color:${Themes.redLight};
   }
   @media (max-width:765px){
      & > div{
          flex-wrap:wrap ;
      }
    
      .select-cont{
        width:100%;
      }
   }
    `
            