import React, { useEffect, useState }  from 'react'
import { CustomTable, FilterAndSearch, PageLayout,Toaster } from '../../components'
import { Requests } from '../../services/requests';
import { Edit ,Delete} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { StyledListMedcine } from './ListMedcine.styled'
import useToast from '../../custome_hook/useToast';
    export default function ListMedcine() {
        const navigate =    useNavigate();
        const [loading,setLoading] = useState(true);
        const theader = ["Medcine id","Name","Manufacture","Generic Name","Strength","quota","Unit","Status"]
        const [listData,setListData] = useState([]);
        const [listDatainit,setListDatainit] = useState([]);
        const [toastMes,setToastMes] = useToast();
        const searchBy  = [{key:"generic_name",name:"Generic Name"},{key:"medcin_id",name:"Medicine Id"}];
        const filters = [{key:"isActive",name:"Status",list:["Active","Inactive"]}];
        const [pageLoading,setPageLoading] = useState(true);
        const handleSearch = (searched)=>{
            setListData(searched)
        }
        const actions = [
            {
                icon:Edit,handler:(ind)=>{
                    navigate(`/edit_medcine/${listData[ind].id}`)
                }
            }
    ];
    useEffect(()=>{
        setListData(listDatainit);
    },[listDatainit])
    useEffect(()=>{
        (async ()=>{
            try{
                setPageLoading(true);
                const res = await Requests.getMedcine();
                if(res.data.success){
                    setListDatainit([...res.data.data.map(d=>{
                        return {
                            id:d._id,
                            medcin_id:d.medicine_id,
                            name:d.name,
                            mfg: d.mfg,
                            generic_name:d.generic_name,
                            strength:d.strength,
                            quota:d.quota,
                            unit:d.unit,
                            isActive: d.isActive ? "Active" : "Inactive"
                    }})]) 
                }
                setLoading(false);
                setPageLoading(false);
            }catch(e){
                console.log(e);
                setLoading(false)
                setPageLoading(false);
            }
        })();
    },[]);

    useEffect(()=>{
        setTimeout(()=>{
          setToastMes("")
         },5000);
       },[toastMes])
    return (
        <PageLayout loading={pageLoading} title='Medicine List'>  
        <Toaster message={toastMes.message} isError={toastMes.isError} /> 
        <StyledListMedcine> 
        <FilterAndSearch searchHandler={handleSearch} data={listDatainit.map(l=>{const {isHQ,...rest} = l;return rest})} searchBy={searchBy} filters={filters} />
        <CustomTable loading={loading} theader={theader} actions={actions} data={listData.map(l=>{const {id,isHQ,...rest} = l;return rest})}/>
        </StyledListMedcine> 
        </PageLayout>  
    )
    }
            