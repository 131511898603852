import styled from "styled-components";
import Themes from "../../style/theme";
export const StyledDashboardList = styled.div`
            .nav-list{
                display:flex;
                flex-direction:column ;
                margin-bottom:10px;
                overflow:hidden ;
                .dropdown-icon{
                    width:15px;
                    align-self:center ;
                    margin-right:5px;
                    opacity:0.7;
                    transform:rotate(90deg) ;
                }
                svg{
                font-size:2.0em;
                padding:0px 5px;
              }
              &.active{
                .dropdown-icon{
                    transform:rotate(0deg) ;
                }
                .selector{
                  overflow:hidden ;
                    background-color:${Themes.primaryGreen};
                    border-radius:5px;
                    color: ${Themes.white};
                    background-color: ${Themes.niceGreen};
                    box-shadow: 0 0 10px 1px rgb(55 160 0 / 70%);
                } 
                .options{
                   max-height:500px;
                }
              }
              .selector{
                  display:flex;
                  width:100%;
                  justify-content:center ;
                  color:${Themes.middleGrey};
                  transition:all 0.5s;
                  &:hover{
                    cursor:pointer;
                    color:${Themes.white};
                  }
                  & > span{
                      flex:1;
                      display:flex;
                      margin:auto;
                      font-size:0.9em;
                      font-weight:500;
                  }
              }
              .options{
                    transition:max-height 0.5s;
                    display:flex;
                    flex-direction:column ;
                    max-height:0;
                    color:${Themes.middleGrey};;
                    overflow:hidden ;
                    & > div{
                        transition:color 0.5s;
                        font-size:0.9em;
                    }
                    & > div:hover{
                       cursor:pointer;
                       color:${Themes.white};
                    }
              }
               
            }
    `
            