
    import React from 'react'
    import { StyledDynamicInput } from './DynamicInput.styled'
    export default function DynamicInput({value="",label="",disabled,type,required,placeholder,error = "",handleInput = ()=>{}}) {
    return (
        <StyledDynamicInput> 
            <div>
            <div><h5>{label}</h5>{required ? <span>*</span> : null}</div>
             <input value={value} className={`${(error.trim() !== "" ?  "error" : "")}`} type={type} autoComplete="none" placeholder={placeholder} required={required} disabled={disabled} onChange={(e)=>handleInput(e.target.value)} />
            </div>
           
     <span>{error}</span>
        </StyledDynamicInput> 
    )
    }
            