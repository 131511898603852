
    import styled from "styled-components";
    import Themes from "../../style/theme";
    export const StyledAddNotify = styled.div`
     form{
        display:flex;
        flex-direction:column ;
        max-width:500px;
        margin:auto;
        button{
            margin-left:0 !important;
        }
        textarea{
            padding:20px;
            font-family:'Nunito Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
       padding: 12px 20px;
       outline: 0;
       border:1px solid black;
       border-radius: 5px;
       color:${Themes.fadeBlack};
       font-size: 14px;
       flex-grow:1;
       border: 1px solid #e4e5e7;
       padding:6 12;
       height:300px;
       resize:none ;
       &:focus{
          box-shadow:0px 0px 3px ${Themes.niceGreen};
       }
       &.error{
        box-shadow:0px 0px 3px ${Themes.redLight};
       }
        }
     }
    `
            