import React , { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Toaster} from '../../components'
import { Requests } from '../../services/requests';
import validator,{nameFieldValidator, numberFieldValidator,emptyFieldValidator, passwordValidator, phoneValidator} from "../../services/validator";
import { CustomeDropdown,FileInput,DynamicInput, FormBtn, GoogleMap, PageLayout } from '../../components'
    import { StyledAddInsured } from './AddInsured.styled'
    export default function AddInsured() {
        const list = ["Active","Inactive"];
        const listGender = ["Male","Female"];
        const [name,setName] = useState({value:"",isValid:"false",error:""});
        const [age,setAge] = useState({value:"",isValid:"false",error:""});
        const [dob,setDob] = useState({value:"",isValid:"false",error:""});
        const [phone,setPhone] = useState({value:"",isValid:"false",error:""});
        const [city,setCity] = useState({value:"",isValid:"false",error:""});
        const [subCity,setSubcity] = useState({value:"",isValid:"false",error:""});
        const [wereda,setWereda] = useState({value:"",isValid:"false",error:""});
        const [houseno,setHouseno] = useState({value:"",isValid:"false",error:""});
        const [insuranceId,setInsuranceId] = useState({value:"",isValid:"false",error:""});
        const [insuranceName,setInsuranceName] = useState({value:"",isValid:"false",error:""});
        const [username,setUsername] = useState({value:"",isValid:"false",error:""});
        const [password,setPassword] = useState({value:"",isValid:"false",error:""});
        const [confirmPassword,setConfirmPassword] = useState({value:"",isValid:"false",error:""});

        const [selectedGender,setSelectedGender] = useState(null);

        const [selectErrorGender,setSelectErrorGender] = useState("");
        const [loading ,setLoading] = useState(false);
        const [toastMes,setToastMes] = useState({message:"",isError:false});
        const navigate = useNavigate();
        const [img,setImg] = useState(null);
        const [imgError,setImgError] = useState("");
        const [insuranceImg,setInsuranceImg] = useState(null);
        const [insuranceImgError,setInsuranceImgError] = useState("");
     
        const handleName = (val)=>{
            const {message,result} = validator(val ,[emptyFieldValidator, nameFieldValidator]);
            setName({value:val,isValid:result,error:message});
        }
   
        const handelAge = (val)=>{
            const {message,result} = validator(val ,[emptyFieldValidator]);
            setAge({value:val,isValid:result,error:message});
        }
        const handleDob = (val)=>{
            const {message,result} = validator(val ,[emptyFieldValidator]);
            setDob({value:val,isValid:result,error:message});
        }
   
        const handlePhone = (val)=>{
            const {message,result} = validator(val ,[emptyFieldValidator,phoneValidator]);
            setPhone({value:val,isValid:result,error:message});
        }
        

        const handleCity = (val)=>{
            const {message,result} = validator(val ,[emptyFieldValidator, nameFieldValidator]);
            setCity({value:val,isValid:result,error:message});
        }

        const handleSubcity = (val)=>{
            const {message,result} = validator(val ,[emptyFieldValidator, nameFieldValidator]);
            setSubcity({value:val,isValid:result,error:message});
        }

        const handleWereda = (val)=>{
            const {message,result} = validator(val ,[emptyFieldValidator]);
            setWereda({value:val,isValid:result,error:message});
        }
 
        const handleHouseno = (val)=>{
            const {message,result} = validator(val ,[emptyFieldValidator]);
            setHouseno({value:val,isValid:result,error:message});
        }

        const handleInsuranceId = (val)=>{
            const {message,result} = validator(val ,[emptyFieldValidator]);
            setInsuranceId({value:val,isValid:result,error:message});
        }

        const handleInsuranceName = (val)=>{
            const {message,result} = validator(val ,[emptyFieldValidator, nameFieldValidator]);
            setInsuranceName({value:val,isValid:result,error:message});
        }

        const handleUsername = (val)=>{
            const {message,result} = validator(val ,[emptyFieldValidator]);
            setUsername({value:val,isValid:result,error:message});
        }
 
        const handlePassword = (val)=>{
            const {message,result} = validator(val ,[emptyFieldValidator, passwordValidator]);
            setPassword({value:val,isValid:result,error:message});
        }
        const handleConfirmPassword = (val)=>{
            const {message,result} = validator(val ,[]);
            if(val==password.value)  setConfirmPassword({value:val,isValid:result,error:message});
            else setConfirmPassword({value:val,isValid:false,error:"Password mismatch"});
         }

       const handleSelectedGender = (ind) =>{
        setSelectedGender(ind);
        setSelectErrorGender("");
   }
        const handleFileChange = (data)=>{
            setImg(data);
      }
      const handleInsuranceFileChange = (data)=>{
        setInsuranceImg(data);
  }
      useEffect(()=>{
        setTimeout(()=>{
          setToastMes("")
         },5000);
       },[toastMes]);
       const handleSubmit = async (e)=>{
          e.preventDefault();
        
          if(name.isValid&&
            age.isValid&&
            dob.isValid&&
            phone.isValid&&
            city.isValid&&
            subCity.isValid&&
            wereda.isValid&&
            houseno.isValid&&
            insuranceId.isValid&&
            insuranceName.isValid&&
            username.isValid&&
            password.isValid&&
            confirmPassword.isValid&&
            img!==null&&
            insuranceImg!==null&&
            selectedGender!==null
            ){
                setLoading(true);
            try{ 
               const res = await Requests.addInsuranceUser({
                name:name.value,
                age:age.value,
                gender: listGender[selectedGender],
                dob: dob.value,
                phone: phone.value,
                city: city.value,
                sub_city: subCity.value,
                woreda: wereda.value,
                house_no: houseno.value,
                image:img,
                insurance_id: insuranceId.value,
                insurance_name: insuranceName.value,
                insurance_image:insuranceImg,
                username:username.value,
                password: password.value,
                isActive:false
              });
               if(res.data.success)
                navigate("/registered")
              else setLoading(false);
            }catch(e){
              if(e.response)
              setToastMes({message:e.response.data.message,isError:true});
              else  setToastMes({message:e.toString(),isError:true});
              setLoading(false);
            }
            setLoading(false);
             console.log(insuranceImg);
          }else{
            setLoading(false);
            if(selectedGender == null) setSelectErrorGender("Select is required");
            if(img==null) setImgError("this field is required")
            if(insuranceImg==null) setInsuranceImgError("this field is required");
            handleName(name.value);
            handelAge(age.value);
            handleDob(dob.value);
            handlePhone(phone.value);
            handleCity(city.value);
            handleSubcity(subCity.value);
            handleWereda(wereda.value);
            handleHouseno(houseno.value)
            handleInsuranceId(insuranceId.value);
            handleInsuranceName(insuranceName.value);
            handleUsername(username.value);
            handlePassword(password.value)
            handleConfirmPassword(confirmPassword.value);
          }
       };
    return (
        <PageLayout loading={false} title='Register'>
             <Toaster message={toastMes.message} isError={toastMes.isError} />
        <StyledAddInsured> 
             <form onSubmit={handleSubmit}> 
               <div>
                <DynamicInput value={name.value} label="Full Name"  type="text" required={true} placeholder="Enter fullname" disabled={loading} error={name.error} handleInput={handleName}/>
                <DynamicInput value={age.value} label="Age"  type="number" required={true} placeholder="Enter age" disabled={loading} error={age.error} handleInput={handelAge}/>
                <DynamicInput value={dob.value} label="Date of Birth"  type="date" required={true} placeholder="Enter date of birth" disabled={loading} error={dob.error} handleInput={handleDob}/>
                <CustomeDropdown label='Gender'  error={selectErrorGender} selected={selectedGender} placeholder="Select.." list={listGender} handleSelect={handleSelectedGender} />
                <DynamicInput value={phone.value} label="Phone"  type="text" required={true} placeholder="Enter phone" disabled={loading} error={phone.error} handleInput={handlePhone}/>
                <DynamicInput value={city.value} label="City"  type="text" required={true} placeholder="Enter city" disabled={loading} error={city.error} handleInput={handleCity}/>
                <DynamicInput value={subCity.value} label="Subcity"  type="text" required={true} placeholder="Enter subcity" disabled={loading} error={subCity.error} handleInput={handleSubcity}/> 
                <DynamicInput value={wereda.value} label="Wereda"  type="text" required={true} placeholder="Enter wereda" disabled={loading} error={wereda.error} handleInput={handleWereda}/>
                <DynamicInput value={houseno.value} label="Houseno"  type="number" required={true} placeholder="Enter houseno" disabled={loading} error={houseno.error} handleInput={handleHouseno}/>
                <DynamicInput value={insuranceId.value} label="Insurance User"  type="Insurance ID" required={true} placeholder="Enter insurance id" disabled={loading} error={insuranceId.error} handleInput={handleInsuranceId}/>
                <DynamicInput value={insuranceName.value} label="Insurance Name"  type="text" required={true} placeholder="Enter insurance name" disabled={loading} error={insuranceName.error} handleInput={handleInsuranceName}/> 
                <DynamicInput value={username.value} label="Username"  type="text" required={true} placeholder="Enter username" disabled={loading} error={username.error} handleInput={handleUsername}/>
                <DynamicInput value={password.value} label="Password"  type="password" required={true} placeholder="Enter password" disabled={loading} error={password.error} handleInput={handlePassword}/>
                <DynamicInput value={confirmPassword.value} label="Confirm Password"  type="password" required={true} placeholder="Enter password again" disabled={loading} error={confirmPassword.error} handleInput={handleConfirmPassword}/>
               <FileInput required={false} error={imgError} label="Upload profile" src={img} handleChange={handleFileChange} />
               <FileInput required={false} error={insuranceImgError} label="Upload Insurance" src={insuranceImg} handleChange={handleInsuranceFileChange} />
             </div>
                <FormBtn isFullWidth={false} disabled={loading} label="Register" />
            </form>
        </StyledAddInsured> 
             </PageLayout>
    )
    }
            