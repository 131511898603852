import styled from "styled-components";
import Themes from "../../style/theme";
export const StyledFormInput = styled.div`
   display: flex;
   flex-direction: column;
   margin-bottom: 10px;
   display:flex;
   flex-direction:column;
   width:100%;
   .input-cont{
    &.focused{
       outline:3px solid ${Themes.inputOutline};
    }
    padding: 7px;
    border-radius:5px ;
    border:1px solid ${Themes.inputBg};
    display:flex;
    align-items:center;
    height:100%;
    & > svg{
        color:${Themes.fadeBlack};
        opacity:0.7;
        cursor: pointer;
    }
   }
   input{
       padding: 7px;
       outline: 0;
       border:none;
       border-radius: 5px;
       width:100%;
       font-family:"arial";
       color:${Themes.inputColor};
       font-weight:100;
       background-color:transparent;
       font-size:1rem;
   }
   span{
       margin-top:10px;
       font-size: 0.8rem;
       color:${Themes.redLight};
   }
   label{
        margin-right:auto;
        margin-top:10px;
        margin-bottom:10px;
        color:${Themes.fadeBlack}
     }
`