import styled from "styled-components";
import Themes from "../../style/theme";
export const StyledDashboardSidebar = styled.div`
      height:100vh;
      background-color:transparent;
      .sidebar-container{
        width:100%;
        max-width:80px;
        height:100vh;
        overflow:auto ;
        transition:all 0.5s;
        background-color:${Themes.niceBlack} ;
        display:flex ;
        flex-direction:column;
        padding:20px;
        
        .side-list-cont{
            &.profile{
                margin:30px 0px;
                height:30px;
            }
            .side-content{
                h1,h3{
                    font-weight:100;
                    font-family:'Nunito Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
                }   
            }
        }
        nav{
                display:flex;
                flex-direction:column ;
                flex:1;
                overflow:hidden;
                -ms-overflow-style: none; 
                scrollbar-width: none;  
          
                &::-webkit-scrollbar {
                    display: none;
                }
                .selector  span ,.selector :last-child {
                    width:0;
                    overflow:hidden;
                    padding:0;
                 
                }
                .selector > span{
                   /* display:none; */
                   opacity:0;
                   font-family:Arial, Helvetica, sans-serif ;
                  
                }
                .selector img:last-child{
                    display:none;
                }
                .options{
                display: none;
                
                }
              
        }
          
    }
    
    .side-list-cont{
            display:flex;
            align-items:center;
            margin-bottom:10px;
           
            & > .space{
               width:10px;
            }
            .side-icon{
                width:50px;
                & > img{
                    width:40px;
                    aspect-ratio:1/1;
                    background-color:${Themes.white} ;
                    border-radius:50%;
                }
            }
            .side-content{
                color:${Themes.white};
                opacity:0;
                overflow:hidden ;
                display:flex;
                flex-direction:column;
                & > span:last-child{
                    font-size:0.8em;
                    opacity:0.5 ;
                }
            }
      }
      &.expanded{
            max-width:250px;
            .options{
               display: flex !important;
               span{
                font-family:Arial, Helvetica, sans-serif ;
               }
               
            }
            nav{
                overflow:auto;
            }
            .selector  span ,.selector img:last-child {
                width:25px !important;
                padding:0 5px;
                display:flex !important;
                opacity:1 !important;
            }
            .selector img:last-child{
                display:flex !important;
            }
            .sidebar-container{
                max-width:250px;
                .side-list-cont{
                    .side-content{
                        opacity:1;
                        overflow:hidden;
                        
                    }
                }
            }
        }
     
      @media (max-width:765px){
        &{
            display:none;
            background-color:${Themes.transprentBlack};
            max-width:100% !important;
            position:fixed;
            top:0;
            right:0;
            left:0;
            bottom:0;
            z-index:2;
        }
        &.expanded{
            display:block;
        }
      }
    `

            