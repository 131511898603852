
    import styled from "styled-components";
import Themes from "../../style/theme";
    export const StyledCustomEditableTable = styled.div`
      width:100%;
      display: flex;
      flex-direction: column;
      margin:auto;
      overflow: auto;
      padding-bottom:100px;
     td > div{
        & > div > span,& > span{
          display:none !important;
        }
      }
      button{
        height: 30px;
        display: flex;
        align-items: center;
        margin-left:0;
      }
      table{
        width:100%;
        border-collapse:collapse;
        flex-grow: 1;
        margin:auto;
      thead{
        font-weight: 900;
      }
        td,td > input{
            color:${Themes.inputColor};
            border:1px solid ${Themes.niceGrey};
            padding:10px;
            &[type="number"]{
              width: 100px !important;
            }
            white-space: nowrap;
            word-break: keep-all;
            font-family: 'Nunito Sans','Helvetica Neue',Helvetica,Arial,sans-serif !important; 
        }
        svg{
            cursor: pointer;
            margin:5px;
        }
      }`
            