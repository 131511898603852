
    import React,{useState,useEffect} from 'react'
    import { PageLayout,DynamicInput,FormBtn, CustomeDropdown, Toaster} from '../../components'
    import validator,{passwordValidator,emptyFieldValidator} from "../../services/validator";
    import { Requests } from '../../services/requests';
    import { useNavigate } from 'react-router-dom';
    import { StyledEditAccount } from './EditAccount.styled'
    export default function EditAccount() {
        const [oldPassword,setOldPassword] = useState({value:"",isValid:"false",error:""});
        const [newPassword,setNewpassword] = useState({value:"",isValid:"false",error:""});
        const [confirmPassword,setConfirmPassword] = useState({value:"",isValid:"false",error:""});
        const [loading ,setLoading] = useState(false);
        const [toastMes,setToastMes] = useState({message:"",isError:false});
        const navigate = useNavigate();
        const handleOldPassoword = (val)=>{
           const {message,result} = validator(val ,[emptyFieldValidator]);
           setOldPassword({value:val,isValid:result,error:message});
        }
        const handleNewPassword = (val)=>{
            const {message,result} = validator(val ,[passwordValidator]);
            setNewpassword({value:val,isValid:result,error:message});
         }
         const handleConfirmPassword = (val)=>{
            const {message,result} = validator(val ,[]);
            if(val==newPassword.value)  setConfirmPassword({value:val,isValid:result,error:message});
            else setConfirmPassword({value:val,isValid:false,error:"Password mismatch"});
         }
        useEffect(()=>{
         setTimeout(()=>{
           setToastMes("")
          },5000);
        },[toastMes]);
        const handleSubmit = async (e)=>{
           e.preventDefault();
           if(oldPassword.isValid&&newPassword.isValid&&confirmPassword.isValid){
             setLoading(true);
             try{ 
                 const res = await Requests.changeOldpwd({oldPassword:oldPassword.value,newPassword:newPassword.value});
                 console.log(res.data);
                 setLoading(false);
                 navigate("/")
             }catch(e){
               setToastMes({message:e.response.data.message,isError:true});
               setLoading(false);
             }
           }else{
              handleOldPassoword(oldPassword.value);
              handleNewPassword(newPassword.value);
              handleConfirmPassword(confirmPassword.value)
           }
         }
      
    return (
        <PageLayout loading={false} title='Change Password' >
        <Toaster message={toastMes.message} isError={toastMes.isError} />
        <StyledEditAccount> 
              <form onSubmit={handleSubmit}>
                    <DynamicInput value={oldPassword.value} label="Old Password"  type="password" required={true} placeholder="Enter Old Password" disabled={loading} error={oldPassword.error} handleInput={handleOldPassoword}/>
                    <DynamicInput value={newPassword.value} label="New Passowrd"  type="password" required={true} placeholder="Type Name" disabled={loading} error={newPassword.error} handleInput={handleNewPassword}/>
                    <DynamicInput value={confirmPassword.value} label="Confirm Password"  type="password" required={true} placeholder="Type Name" disabled={loading} error={confirmPassword.error} handleInput={handleConfirmPassword}/>
                     <FormBtn isFullWidth={false} disabled={loading} label="Change" />
                    </form>
        </StyledEditAccount> 
        </PageLayout>
    )
    }

