
    import React,{useEffect, useState} from 'react'
import CustomeDropdown from '../CustomeDropdown/CustomeDropdown.component';
import DynamicInput from '../DynamicInput/DynamicInput.component';
    import { StyledFilterAndSearch } from './FilterAndSearch.styled'
    export default function FilterAndSearch({data = [] ,filters =  [],searchBy = [],searchHandler = (dat)=>{}}) {
    const [filterlist,setFilterlist] = useState(filters);
    const [searchByList,setSearchBylist] = useState(searchBy);
    const [searchKey,setSearchKey] = useState("");
    const [selectedSearchBy,setSelectedSearchBy] = useState(0);
    const [selectedFilter,setSelectedFilter] = useState(filters.map(f=>0))
    const handleSelectedSearchBy = (ind) =>{
        setSelectedSearchBy(ind);
   }
   const handleSearch = ()=>{
      let final_result = data;
      filters.forEach((f,i)=>{
           if(selectedFilter[i]!=0)
           final_result = final_result.filter((r)=>r[f.key].toUpperCase() === filterlist[i].list[selectedFilter[i]].toUpperCase())
      });
      final_result = final_result.filter(r=>r[searchByList[selectedSearchBy].key].toUpperCase().includes(searchKey ?  searchKey.toString().toUpperCase() : ""))
      searchHandler(final_result);
   }
   useEffect(()=>{
       handleSearch();
   },[selectedSearchBy,selectedFilter,searchKey]);
  useEffect(()=>{
    setFilterlist(filterlist.map(r=>{return {...r,list:["All",...r.list]}}));
    setSelectedFilter(filterlist.map(f=>0))
  },[])
    return (
        <StyledFilterAndSearch> 
            <div> 
                 <DynamicInput value={searchKey} label=""  type="text" required={false} placeholder="Search...." disabled={false} error="" handleInput={(val)=>setSearchKey(val)}/>
                 <CustomeDropdown label='Search By' error="" selected={selectedSearchBy} placeholder="Select.." list={[...searchByList.map(l=>l.name)]} handleSelect={handleSelectedSearchBy} />
            </div>
            <div>
            {
                filterlist.map((f,i)=> <CustomeDropdown label={f.name} error="" selected={selectedFilter[i]} placeholder="Select.." list={f.list} handleSelect={(ind)=>{ setSelectedFilter(selectedFilter.map((s,j)=>(i==j) ? ind : s))}} />)
            }
            </div>
        </StyledFilterAndSearch> 
    )
    }
            