
    import React, { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
    import { StyledLandingNavbar } from './LandingNavbar.styled'
    export default function LandingNavbar() {
    const location = useLocation();
    return (
        <StyledLandingNavbar> 
            <span>
                <img src="/assets/kenema_logo.png" />
                <h1>Kenema Pharmacy</h1>
            </span>
            <div>
            {
                location.pathname == "/" ? <>
                <NavLink to="/login" >Login</NavLink>
                <NavLink to="/register" >Register</NavLink>
            </> : null
           }
            </div>
        
        </StyledLandingNavbar> 
    )
    }
            