import React , { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Toaster} from '../../components'
import { Requests } from '../../services/requests';
import validator,{nameFieldValidator, numberFieldValidator,emptyFieldValidator, passwordValidator} from "../../services/validator";
import { CustomeDropdown,FileInput,DynamicInput, FormBtn, GoogleMap, PageLayout } from '../../components'
    import { StyledAddMedicine } from './AddMedicine.styled'
import useToast from '../../custome_hook/useToast';
    export default function AddMedicine() {
        const list = ["Active","Inactive"];
        const listUnit = ["ml","mg"];
        const [medicineId,setMedcineId] = useState({value:"",isValid:"false",error:""});
        const [name,setName] = useState({value:"",isValid:"false",error:""});
        const [manufacture,setManufacture] = useState({value:"",isValid:"false",error:""});
        const [genericName,setGenericName] = useState({value:"",isValid:"false",error:""});
        const [quota,setQuota] = useState({value:"",isValid:"false",error:""});
        const [strength,setStrength] = useState({value:"",isValid:"false",error:""});
        const [selected,setSelected] = useState(null);
        const [selectError,setSelectError] = useState("");
        const [selectedUnit,setSelectedUnit] = useState(null);
        const [selectErrorUnit,setSelectErrorUnit] = useState("");
        const [loading ,setLoading] = useState(false);
        const [toastMes,setToastMes] = useToast();
        const navigate = useNavigate();
        const handleSelected = (ind) =>{
            setSelected(ind);
            setSelectError("");
       }
       const handleSelectedUnit = (ind) =>{
        setSelectedUnit(ind);
        setSelectErrorUnit("");
   }
        const handleMedcienId = (val)=>{
            const {message,result} = validator(val ,[emptyFieldValidator]);
            setMedcineId({value:val,isValid:result,error:message});
        }
        const handleName = (val)=>{
            const {message,result} = validator(val ,[emptyFieldValidator, nameFieldValidator]);
            setName({value:val,isValid:result,error:message});
        }
        const handleManufacture = (val)=>{
            const {message,result} = validator(val ,[emptyFieldValidator, nameFieldValidator]);
            setManufacture({value:val,isValid:result,error:message});
        }
        const handleGenericName = (val)=>{
            const {message,result} = validator(val ,[emptyFieldValidator, nameFieldValidator]);
            setGenericName({value:val,isValid:result,error:message});
        }
        const handleStrength = (val)=>{
            const {message,result} = validator(val ,[emptyFieldValidator, numberFieldValidator]);
            setStrength({value:val,isValid:result,error:message});
        }
        const handleQuota = (val)=>{
          const {message,result} = validator(val ,[emptyFieldValidator, numberFieldValidator]);
          setQuota({value:val,isValid:result,error:message});
      }
      useEffect(()=>{
        setTimeout(()=>{
          setToastMes("")
         },5000);
       },[toastMes]);
       const handleSubmit = async (e)=>{
          e.preventDefault();
          if(name.isValid&&medicineId.isValid&&genericName.isValid&&strength.isValid&&quota.isValid&&manufacture.isValid&&selected!=null&&selectedUnit!==null){
            setLoading(true);
            try{ 
               const res = await Requests.addMedcine({
                medicine_id:medicineId.value,
                name:name.value,
                mfg:manufacture.value,
                generic_name:genericName.value,
                strength:strength.value,
                quota:quota.value,
                unit:listUnit[selectedUnit],
                isActive:selected === 0 ? true : false
              });
               if(res.data.success)
                navigate("/list_medcine",{state: {message: 'Medicine added Successfully', isError: false }})
              else setLoading(false);
            }catch(e){
              if(e.response)
              setToastMes({message:e.response.data.message,isError:true});
              else  setToastMes({message:e.toString(),isError:true});
              setLoading(false);
            }
            setLoading(false);
          }else{
            if(selected == null) setSelectError("Select is required");
            if(selectedUnit == null) setSelectErrorUnit("Select is required");
            handleMedcienId(medicineId.value)
            handleName(name.value)
            handleManufacture(manufacture.value)
            handleGenericName(genericName.value)
            handleStrength(strength.value);
            handleQuota(quota.value);
          }
       };
    return (
        <PageLayout loading={false} title='Add Medicine'>
             <Toaster message={toastMes.message} isError={toastMes.isError} />
        <StyledAddMedicine>      
        <form onSubmit={handleSubmit}> 
           <div>
              <DynamicInput value={medicineId.value} label="Medcine Id"  type="text" required={true} placeholder="Enter medcine id" disabled={loading} error={medicineId.error} handleInput={handleMedcienId}/>
                <DynamicInput value={name.value} label="Name"  type="text" required={true} placeholder="Enter name" disabled={loading} error={name.error} handleInput={handleName}/>
                <DynamicInput value={manufacture.value} label="Manufacture"  type="text" required={true} placeholder="Enter manufacture" disabled={loading} error={setManufacture.error} handleInput={handleManufacture}/>
                <DynamicInput value={genericName.value} label="Generic Name"  type="text" required={true} placeholder="Enter generic name" disabled={loading} error={genericName.error} handleInput={handleGenericName}/>
                <DynamicInput value={strength.value} label="Strength"  type="number" required={true} placeholder="Enter strength" disabled={loading} error={strength.error} handleInput={handleStrength}/> 
                <DynamicInput value={quota.value} label="Quota"  type="number" required={true} placeholder="Enter quota" disabled={loading} error={quota.error} handleInput={handleQuota}/>   
                <CustomeDropdown label='Unit' error={selectError} selected={selectedUnit} placeholder="Select.." list={listUnit} handleSelect={handleSelectedUnit} />
               <CustomeDropdown label='Status' error={selectErrorUnit} selected={selected} placeholder="Select.." list={list} handleSelect={handleSelected} />
           </div>  
                <FormBtn isFullWidth={false} disabled={loading} label="Add" />
            </form>
        </StyledAddMedicine>
        </PageLayout>
    )
    }
            