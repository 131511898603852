
    import { Add } from '@mui/icons-material'
import { display, flexbox } from '@mui/system'
import loader from "../../loading.svg";
import React from 'react'
    import { StyledPageLayout } from './PageLayout.styled'

    export default function PageLayout({loading = true,title = "",actions = [],children}) {
    return (
        <StyledPageLayout> 
            <header>
                <h6>{title}</h6>
                <nav>
                    {
                        actions.map( (a,i)=> <button key={i} onClick={a.handler}>
                            <a.icon />
                            <span>{a.name}</span>
                        </button>)
                    }
                </nav>
            </header>
            <main>
           
            {loading ? <div style={{display:"flex"}}>
                <span style={{margin:"auto",display:"inline-block",width:"100px"}}>  
                    <img src={loader} width="50px" />
               </span> </div> : children}
            </main>
        </StyledPageLayout> 
    )
    }
            