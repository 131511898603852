import React, { useEffect, useState } from 'react'
import { Add, Delete, List, ListAltOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom';
import { Toaster} from '../../components'
import {  CustomEditableTable, DynamicInput, FormBtn, PageLayout,CustomeDropdown } from '../../components'
import validator,{emptyFieldValidator,takeTimeValidator} from "../../services/validator";
import { Requests } from '../../services/requests';
import { StyledAddPescription } from './AddPescription.styled'
    export default function AddPescription() {
      const [insuranceList ,setInsuranceList] = useState([]);
      const [prescriptionID,setPrescriptionId] = useState({value:"",isValid:"false",error:""});
      const [prescriptionDate,setPrescriptionDate] = useState({value:"",isValid:"false",error:""});
      const [hosipital,setHospital] = useState({value:"",isValid:"false",error:""});
      const [selected,setSelected] = useState(null);
      const [selectError,setSelectError] = useState("");
      const [selectedMedicine,setSelectedMedicine] = useState(null);
      const [selectErrorMedicine,setSelectErrorMedicine] = useState("");
      const [selectedInsuranceId,setSelectedInsuranceId] = useState(null);
      const [selectErrorInsuranceId,setSelectErrorInsuranceId] = useState("");
      const [pageLoading,setPageLoading] = useState(true);

      const [batchDate,setBatchDate] = useState({value:"",isValid:"false",error:""});
      const [medcines,setMedcines] = useState([]);
      const [selectedMeds , setSelectedMeds] = useState([]);
      const [meds,setMeds] = useState([]); 
      const [toastMes,setToastMes] = useState({message:"",isError:false});
      const [toastTimeout,setToastTimeout] = useState(0);
      const [loading ,setLoading] = useState(false);
      const navigate = useNavigate();
      const date_converter = (d)=>{
        d = Number(d);
        return d  < 10 ? "0"+d : d;
      }
      const handleAddRow = async ()=>{ 
        if(medcines.length == 0){
          setToastMes({message:"No medcines found ",isError:true});
        }{
          console.log(medcines);
          const total_dosage = medcines[0].total_dosage;
          const total_day  = (Number(total_dosage) * 1) / 1;
          let dt = new Date();
          dt.setDate(dt.getDate() + total_day);
          const date_split = dt.toLocaleDateString().split("/");    
          setMeds([...meds,  {
            medicine:medcines[0].id,
            stock:medcines[0].stock,
            unit:medcines[0].unit,
            quantity:1,
            dataOfManufacturePres:medcines[0].dataOfManufacturePres,
            dateOfExpirePres:medcines[0].dateOfExpirePres,
            dosage:1,
            refilleDate: `${date_converter(date_split[2])}-${date_converter(date_split[0])}-${date_converter(date_split[1])}`,
            takeTime:""
          }]);
          setSelectedMeds([...selectedMeds,0]);
        }
      }
  
      const handleInputChange = (ind,key,value)=>{
        setMeds(meds.map((m,i)=>{
          if(i==ind){ 
            m[key] = value;
            const total_dosage = medcines.find(me=>me.id == m.medicine).total_dosage;
            const total_day  = (Number(total_dosage) * Number(m["quantity"])) / Number(m["dosage"]);
            let dt = new Date();
            dt.setDate(dt.getDate() + total_day);
            const date_split = dt.toLocaleDateString().split("/");
             m["refilleDate"] = `${date_converter(date_split[2])}-${date_converter(date_split[0])}-${date_converter(date_split[1])}`;     
            return m;
          }
          else return m;
        }));
      }
      const theader = [
        "Medcine",
        "Stock",
        "Quantity",
        "Date of Manufacture",
        "Date of Expire",
        "Dosage",
        "Refille Date",
        "Take Time"
      ]
      const actions = [
        {icon:Delete,handler(ind){
          setMeds(meds.filter((d,i)=>i!=ind));
        }
      }
      ]
      const handleNameInput = (val)=>{
        const {message,result} = validator(val ,[emptyFieldValidator]);
        setBatchDate({value:val,isValid:result,error:message});
       }
  const headerActions = [
      // {icon:List,name:"List Batch",handler:()=>console.log("clicked")}
  ];
  const handlePrescriptionId = (val)=>{
    const {message,result} = validator(val ,[emptyFieldValidator]);
    setPrescriptionId({value:val,isValid:result,error:message});
}

const handlePrescriptionDate = (val)=>{
    const {message,result} = validator(val ,[emptyFieldValidator]);
    setPrescriptionDate({value:val,isValid:result,error:message});
}
const handleHospital = (val)=>{
    const {message,result} = validator(val ,[emptyFieldValidator]);
    setHospital({value:val,isValid:result,error:message});
}
const handleSelected = (ind) =>{
    setSelected(ind);
    setSelectError("");
}
const handleSelectedInsuranceId = (ind) =>{
    setSelectedInsuranceId(ind);
    setSelectErrorInsuranceId("");
}

  const handleMedDropdownChange = (i,ind)=>{
      setSelectedMeds(selectedMeds.map((sm,j)=>(i===j) ? ind : sm))
      setMeds([...meds.map((sm,j)=>(i===j) ? {...sm,medicine:medcines[ind].id,stock:medcines[ind].stock,dateOfExpirePres:medcines[ind].dateOfExpirePres,dataOfManufacturePres:medcines[ind].dataOfManufacturePres} : sm)])
  }
  const handleSubmit = async (e)=>{
    e.preventDefault();
      if(selectedInsuranceId!==null&&prescriptionID.isValid&&prescriptionDate.isValid&&hosipital.isValid){
        if(meds.filter(m=>!validator(m.takeTime ,[takeTimeValidator]).result).length > 0){
          setToastMes({message:"Invalid take time value",isError:true});
          return;
        }
        setLoading(true);
        try{ 
          const res = await Requests.addPrescription({
           prescription_id:prescriptionID.value,
           prescription_date:prescriptionDate.value,
           hosipital:hosipital.value,
           insuranceId:insuranceList[selectedInsuranceId].id,
           medcines:meds
         });
          if(res.data.success)
           navigate("/list_prescription",{state:{message:"Prescription added successfully",isValid:true}})
         else setLoading(false);
       }catch(e){
         console.log("failed");
         setToastMes({message:e.response.data.message,isError:true});
         setLoading(false);
       }
      }
      else if(selectedInsuranceId==null){
        setSelectErrorInsuranceId("please select insurece user")
      }
    }
    useEffect(()=>{
     console.log(toastTimeout,toastMes)
     clearTimeout(toastTimeout);
     if(toastMes.message.trim()!="")
     setToastTimeout(setTimeout(()=>{
        console.log("chersual")
        setToastMes({message:"",isValid:true})
       },5000));
     },[toastMes]);
    
    useEffect(()=>{
          (async () =>{
            setPageLoading(true);
              const res = await Requests.getMedcinePharm();
             let resb = await Requests.getBatchPharm();
             let reqi = await Requests.getInsuranceUser();
             resb = {data:{data: resb.data.data.filter((r=>{
              const current = new Date();
              const expdate = new Date(r.date_expire)
              return (current < expdate)
            }))}}
            reqi  = {data:{data: reqi.data.data.filter((r=>{
              return r.isActive
            }))}}
             setInsuranceList([...reqi.data.data.map(d=>{return{name:d.name,insuranceId:d.insurance_id,id:d._id}})])
             setMedcines([...resb.data.data.map(d=>{
              const temp_med = res.data.data.find(m=>m._id === d.medicine_id);
              return {
              unit:temp_med.unit,
              name:[d.batch_date,temp_med.name,temp_med.generic_name,temp_med.mfg,temp_med.strength,temp_med.unit].join(",")
                ,
              id:d._id,
              stock:d.quantity,
              total_dosage:temp_med.strength,
              dateOfExpirePres:d.date_expire,
              dataOfManufacturePres:d.date_mfg
            }})]);
          
             setSelectedMeds([...meds.map(m=>0)]);
             const date = new Date();
             const formatter = new Intl.DateTimeFormat("en-GB", { 
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            });
           setPageLoading(false);
            const today = formatter.format(date)
            setPrescriptionDate({value:today.split("/").reverse().join("-"),isValid:true,error:""})
          })();  
    },[])
  return (
      <PageLayout loading={pageLoading} title='Add Prescription' actions={headerActions}>
          <Toaster message={toastMes.message} isError={toastMes.isError} />
        <StyledAddPescription> 
        <form onSubmit={handleSubmit}>
               <div className='form-cont'>  
                <DynamicInput value={prescriptionID.value} label="Prescription ID"  type="text" required={true} placeholder="Enter prescription ID" disabled={loading} error={prescriptionID.error} handleInput={handlePrescriptionId}/>
                <DynamicInput value={prescriptionDate.value} label="Prescription Date"  type="date" required={true} placeholder="Enter prescription Date" disabled={loading} error={prescriptionDate.error} handleInput={handlePrescriptionDate}/>
                <DynamicInput value={hosipital.value} label="Hospital Name"  type="text" required={true} placeholder="Enter hospital name" disabled={loading} error={hosipital.error} handleInput={handleHospital}/>
                <CustomeDropdown searchRequired={true} label='insured user' error={selectErrorInsuranceId} selected={selectedInsuranceId} placeholder="Select.." list={insuranceList.map(m=>[m.insuranceId,m.name].join(","))} handleSelect={handleSelectedInsuranceId} />
                </div> 
                <CustomEditableTable selectedMeds={selectedMeds} handleMedDropdownChange={handleMedDropdownChange} meds={medcines} handleInputChange={handleInputChange} handleAddRow={handleAddRow} theader={theader} actions={actions} data={meds.map(d=>{
                  return  {
                    medicine:d.medicine,
                    stock:d.stock,
                    quantity:d.quantity,
                    dataOfManufacturePres:d.dataOfManufacturePres,
                    dateOfExpirePres:d.dateOfExpirePres,
                    dosage:d.dosage,
                    refilleDate:d.refilleDate,
                    takeTime:d.takeTime
                  }
                })} />
                <FormBtn isFullWidth={false} disabled={loading} label="Add" />
            </form>
        </StyledAddPescription> 
        </PageLayout>
    )
    }
            