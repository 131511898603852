
import React, { useEffect, useState }  from 'react'
import { CustomTable,FilterAndSearch, PageLayout,Toaster } from '../../components'
import { Requests } from '../../services/requests';
import { Edit ,Delete} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { StyledListBranch } from './ListBranch.styled'
import useToast from '../../custome_hook/useToast';
    export default function ListBranch() {
        const navigate =    useNavigate();
        const [loading,setLoading] = useState(true);
        const theader = ["Name","Phone","Address","Wereda","Housenumber","Status"]
        const [listData,setListData] = useState([]);
        const [listDatainit,setListDatainit] = useState([]);
        const [toastMes,setToastMes] = useToast();
        const searchBy  = [{key:"name",name:"Branch Name"}];
        const [pageLoading,setPageLoading] = useState(true);
        const filters = [{key:"isActive",name:"Status",list:["Active","Inactive"]}];
        const handleSearch = (searched)=>{
            setListData(searched)
        }
    const actions = [
        {
            icon:Edit,handler:(ind)=>{
               navigate(`/edit_branch/${listData[ind].id}`)
            }
        }
    ];
    useEffect(()=>{
        setListData(listDatainit);
    },[listDatainit])
    useEffect(()=>{
        (async ()=>{
            try{
                setPageLoading(true);
                const res = await Requests.getBranch();
                if(res.data.success){
                    setListDatainit([...res.data.data.map(d=>{
                        return {
                        id:d._id,
                        name:d.name,
                        phone:d.phone,
                        address:d.address,
                        wereda:d.wereda ?? "",
                        housenum:d.housenumber ?? "",
                        isHQ:d.isHQ,
                        isActive: d.isActive ? "Active" : "Inactive"
                    }})])
                }
                setLoading(false);
                setPageLoading(false);
            }catch(e){
                console.log(e.toString());
                setToastMes({message:e.toString(),isError:true})
                setLoading(false)
                setPageLoading(false);
            }
        })();
    },[]);
    useEffect(()=>{
        setTimeout(()=>{
          setToastMes("")
         },5000);
       },[toastMes])
    return (
        <PageLayout loading={pageLoading} title='List Branch'>  
        <Toaster message={toastMes.message} isError={toastMes.isError} />  
        <StyledListBranch> 
        <FilterAndSearch searchHandler={handleSearch} data={listDatainit.map(l=>{const {isHQ,...rest} = l;return rest})} searchBy={searchBy} filters={filters} />
        <CustomTable loading={loading} theader={theader} actions={actions} data={listData.map(l=>{const {id,isHQ,...rest} = l;return rest})}/>
        </StyledListBranch>
        </PageLayout> 
    )
    }
            