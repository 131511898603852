
    import styled from "styled-components";
    export const StyledToaster = styled.div`
      position:fixed;
      right:0;
      margin-top:-80px;
      background-color:blue;
      padding:20px;
      border-radius:10px;
      margin-right:20px;
      color: #155724;
      background-color: #d4edda;
      border-radius: 0.25rem;
      font-size: 1.2em;
      display:flex;
      justify-content:space-between;
      gap:10px;
      max-width:100%;
      align-items:center;
      box-shadow:0 6px 12px rgb(147 159 171 / 0.5);
      svg{
        cursor:pointer;
      }
      span{
        font-family: 'Nunito Sans','Helvetica Neue',Helvetica,Arial,sans-serif !important; 
      }
      
      &.toast-error{
         color: #721c24;
         background-color: #f8d7da;
      }
`
            