
    import styled from "styled-components";
    export const StyledAddPescription = styled.div`
    max-width:1200px;
     margin:auto;
     & > form > div{
        display: grid !important;
        grid-template-columns: repeat(auto-fit,minmax(300px ,1fr));
        justify-content:start;
        justify-items:center;
        align-content:stretch;
        align-items:stretch;
        grid-gap: 10px;
        & > *{
            width:100%;
            max-width:500px ;
        }
        &:nth-child(2){
            display:flex !important;
            flex-direction:column;
            align-items:stretch;
            & > table{
                max-width:100%;
                margin:0;
            }
            button{
                width:auto !important;
            }
        }
     }
     .med-table{
        display: flex !important;
        flex-direction: column;
        align-items: stretch;
        & > div{
            max-width: 100%;
            margin:0;
        }
     }
    `
            