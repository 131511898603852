
    import { Close } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
    import { StyledToaster } from './Toaster.styled'
    export default function Toaster({message = "",isError = false}) {
    const [mes, setMes] = useState("");
    useEffect(()=>{
       setMes(message);
    },[message]);
    return (
        <>
           {mes.trim() !== "" ? <StyledToaster className={isError ? "toast-error" : ""}> 
           <span>{mes}</span><Close onClick={()=>setMes("")} />
           </StyledToaster> : null}
        </>
    )
    }
            