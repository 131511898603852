import styled from "styled-components";
import Themes from "../../style/theme";
export const StyledDashboardLayout = styled.div`
      display:flex;
      align-items:stretch;
      height:100%;
      min-height:100vh;
      width:100%;

      overflow: auto;
      .dashbord-main-contianer{
        flex:1;
        flex-direction:column;
       background-color:${Themes.niceGrey};
       display:flex;
       .dashboard-pageview{
          height:93vh;
          overflow:auto;
          display:flex;
          flex-direction:column;
          width:100%;
          position: relative;
        
          & > *:first-child{
             position:absolute;
             left:0;
             right:0;
          }
          footer{
            margin-top:auto;
            background-color:${Themes.white} ;
          }
       }
      }
      @media (max-width:765px){
        .dashbord-main-contianer{
          max-width: 100vw;
        }
      }
    
    `
            