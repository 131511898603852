
    import styled from "styled-components";
import Themes from "../../style/theme";
    export const StyledPageLayout = styled.div`
      background-color:${Themes.white};
      margin:20px;
      display:flex;
      flex-direction:column;
      & > header{
        border-bottom:1px solid  ${Themes.niceGrey};
        padding:5px 20px;
        display:flex;
        justify-content:space-between ;
        align-items:center ;
       & > h6{
            font-family: 'Nunito Sans','Helvetica Neue',Helvetica,Arial,sans-serif !important; 
            font-size: 17px !important;
            font-weight: 600 !important;
            box-sizing: border-box;
            color: ${Themes.fadeBlack};
            opacity:0.8;
            padding:5px;
        }
        & > nav{
            display:flex;
            flex-wrap:wrap;
            gap:5px;
            width:auto;
            button{
             display:flex;
             align-items:center;
              margin-right:20px;
              border:none;
              padding:5px;
              background-color:${Themes.niceGreen};
              color:${Themes.white};
              border-radius:3px ;
              box-shadow: 0 2px 6px 0 rgb(40 167 69 / 50%);
              transition:background-color 0.5s;
              &:hover{
                background-color:${Themes.primaryGreenDark};
                cursor: pointer;
              }
              span{
                font-size: 14px;
                font-weight: 400;
                font-family: 'Nunito Sans','Helvetica Neue',Helvetica,Arial,sans-serif !important; 
              }
            }
        }
     
      }
      & > main{
        margin:20px;
      }
      @media (max-width:765px){
        &{
          margin:10px;
        }
        & > header{
            & > nav{
                flex-direction:column;
            }
        }
      }
    `
            