
import React, { useEffect, useState } from 'react'
import { Add, Delete, List, ListAltOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom';
import { Toaster} from '../../components'
import {  CustomEditableTable, DynamicInput, FormBtn, PageLayout } from '../../components'
import { StyledAddBatch } from './AddBatch.styled'
import validator,{emptyFieldValidator} from "../../services/validator";
import { Requests } from '../../services/requests';
import useToast from '../../custome_hook/useToast';
    export default function AddBatch() {
        const [batchDate,setBatchDate] = useState({value:"",isValid:"false",error:""});
        const [medcines,setMedcines] = useState([]);
        const [selectedMeds , setSelectedMeds] = useState([]);
        const [meds,setMeds] = useState([]); 
        const [pageLoading,setPageLoading] = useState(true);
        const [toastMes,setToastMes] = useToast();
        const [loading ,setLoading] = useState(false);
        const navigate = useNavigate();
        const handleAddRow = ()=>{
          if(medcines.length == 0){
            setToastMes({message:"No medcines found ",isError:true});
          }{
          setMeds([...meds,  {
            medicine:medcines[0].id,
            quantity:0,
            dataOfManufacture:"",
            dateOfExpire:"",
          }]);
          setSelectedMeds([...selectedMeds,0]);
        }
        }
        const handleInputChange = (ind,key,value)=>{
          setMeds(meds.map((m,i)=>{
            if(i==ind){ 
              m[key] = value;
              return m;
            }
            else return m;
          }));
        }
        const theader = [
          "Medcine",
          "Quantity",
          "Date of Manufacture",
          "Date of Expire"
        ]
        const actions = [
          {icon:Delete,handler(ind){
            setMeds(meds.filter((d,i)=>i!=ind));
          }
        }
        ]
        const handleNameInput = (val)=>{
          console.log(val);
          const {message,result} = validator(val ,[emptyFieldValidator]);
          setBatchDate({value:val,isValid:result,error:message});
         }
    const headerActions = [
        // {icon:List,name:"List Batch",handler:()=>console.log("clicked")}
    ];
    const handleMedDropdownChange = (i,ind)=>{
        setSelectedMeds(selectedMeds.map((sm,j)=>(i===j) ? ind : sm))
        setMeds([...meds.map((sm,j)=>(i===j) ? {...sm,medicine:medcines[ind].id} : sm)])
    }
    const handleSubmit = async (e)=>{
      e.preventDefault();
      if(meds.length == 0){
        setToastMes({message:"No medicine added",isError:true});
        return;
      }
     

      for(let m of meds){
        const temp = new Date(m.dataOfManufacture);
        const now = new Date();
        if(temp.getTime() > now.getTime()){
          setToastMes({message:"Invalid manufacture date",isError:true});
          return;
        }
      }
        setLoading(true);
        try{ 
           const res = await Requests.saveBatch({
            batch_date:batchDate.value,
            batch_medicine:meds
          });
           if(res.data.success)
            navigate("/list_batch",{state:{message:"Batch added Sccessfully",isError:false}})
          else setLoading(false);
        }catch(e){
          setToastMes({message:e.response.data.message,isError:true});
          setLoading(false);
        }
        setLoading(false);
      }
      useEffect(()=>{
        setTimeout(()=>{
          setToastMes({message:"",isError:true})
         },5000);
       },[toastMes]);
      
      useEffect(()=>{
            (async () =>{
              setPageLoading(false);
              console.log("started")
               const res = await Requests.getMedcine();
               console.log(res.data);
               setMedcines([...res.data.data.map(d=>{return {
                name:[d.name,d.medicine_id,d.generic_name,d.mfg,d.unit].join(","),
                id:d._id
              }})]);
               setSelectedMeds([...meds.map(m=>0)]);
               const date = new Date();
               const formatter = new Intl.DateTimeFormat("en-GB", { 
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              });
              const today = formatter.format(date)
               setBatchDate({value:today.split("/").reverse().join("-"),isValid:true,error:""})
               setPageLoading(false);
            })();  
      },[])
    return (
        <PageLayout loading={pageLoading} title='Add Batch' actions={headerActions}>
            <Toaster message={toastMes.message} isError={toastMes.isError} />
        <StyledAddBatch> 
            <form onSubmit={handleSubmit}>
                <CustomEditableTable selectedMeds={selectedMeds} handleMedDropdownChange={handleMedDropdownChange} meds={medcines} handleInputChange={handleInputChange} handleAddRow={handleAddRow} theader={theader} actions={actions} data={meds.map(d=>{
                  return  {
                    medicine:d.medicine,
                    quantity:d.quantity,
                    dataOfManufacture:d.dataOfManufacture,
                    dateOfExpire:d.dateOfExpire
                  }
                })} />
                <FormBtn isFullWidth={false} disabled={loading} label="Add" />
            </form>
        </StyledAddBatch> 
        </PageLayout>

    )
    }
            