
    import styled from "styled-components";
    export const StyledListBatchPharm = styled.div`
     .pop-up{
        position:fixed;
        top:0;
        right:0;
        left:0;
        bottom:0;
        background-color:rgb(0 0 0 /0.5);
        z-index:9999999999;
        display:flex;
        justify-content:center ;
        align-items:center ;
        overflow:auto ;
        & > div{
            background-color:white ;
            max-width:800px ;
            overflow:auto ;
        }
      }
    `
            