
    import React,{useEffect, useRef, useState} from 'react'
    import { StyledDashboardSidebar } from './DashboardSidebar.styled'
    import {useSelector} from "react-redux";
    import { SignalWifiStatusbarNullOutlined} from "@mui/icons-material";
import DashboardList from '../DashboardList/DashboardList.component';
    export default function DashboardSidebar({isExpanded=false,handleExpanded,navs = []}) {
        const cont = useRef(null);
        const user  = useSelector((state) => state.user.user);
        const handleDropdown = (ind)=>{
              setNavList(navList.map((n,i)=>{
                if(ind === i) if(n.isActive) n.isActive = false; else n.isActive = true;
                else n.isActive = false;
                return n;
              }))
        }
        const [navList,setNavList] = useState(navs);

    return (
        <StyledDashboardSidebar onClick={()=>handleExpanded(false)}  className={(isExpanded) ? "expanded" : ""} ref={cont} onMouseEnter={(e)=> !isExpanded ? cont.current.classList.add("expanded") : null} onMouseLeave={(e)=> !isExpanded ? cont.current.classList.remove("expanded") : null}  > 
            <div onClick={(e)=>e.stopPropagation()} className='sidebar-container'>
                <div className='side-list-cont'>
                <div className='side-icon'><img src="/assets/kenema_logo.png" /></div>
                <span className='space'></span>
                <div className='side-content'><h1>Kenema</h1></div>
                </div>
                <div className='side-list-cont profile'>
                <div className='side-icon'><img  src={user.profile ?? "/assets/profile.jpg"} /></div>
                <span className='space'></span>
                <div className='side-content'>
                   <span><h3>{user.name}</h3></span>
                   <span>{user.username}</span>
                </div>
                </div>
                <nav>
                   {navList.length > 0 ? navList.map((d,i)=>
                      <DashboardList index={i} handleExpanded={handleExpanded} handleDropdown={handleDropdown} data={d} />
                   ) : null}
                </nav>
            </div>
        </StyledDashboardSidebar> 
    )
    }
            