    import { DeleteSweep, ListAltOutlined, LocalShipping, MedicationOutlined, PermIdentityOutlined, Person, Person4Outlined, StoreOutlined } from '@mui/icons-material'
    import React, { useEffect, useState } from 'react'
    import { PageLayout } from '../../components'
    import { Requests } from '../../services/requests';
    import {useSelector} from "react-redux";
    import { StyledDashBoardAdmin } from './DashBoardAdmin.styled'
    export default function DashBoardAdmin() {
        const user  = useSelector((state) => state.user.user);
        const [statData,setStatData] = useState({
            insuranceUser:0,
            batch:0,
            prescription:0,
            expired:0,
            outofStock:0,
            batches:0,
            insureduser:0,
            pendingInsuredUser:0
        });
        const [pageLoading,setPageLoading] = useState(true);
        useEffect(()=>{
            (async ()=>{
                    setPageLoading(true);
                    try{
                        const meds = await Requests.getMedcine();
                        const branch = await Requests.getBranch();
                        const pharm = await Requests.getPharmacist();
                        const exp = await Requests.getBatchExpiredAdmin();
                        const outofstock =  await Requests.getBatchOutOfStockAdmin();
                        const insuredUsers = await Requests.getInsuranceUser();
                        const pendingInusredUser = await Requests.getPendingInsuranceUser();
                        const batches = await Requests.getBatch();
                        console.log(branch);
                        setStatData({
                            branch:branch.data.data.length,
                            pharm:pharm.data.data.length,
                            med:meds.data.data.length,
                            expired:exp.data.data.length,
                            outofStock:outofstock.data.data.length,
                            insureduser:insuredUsers.data.data.length,
                            pendingInsuredUser:pendingInusredUser.data.data.length,
                            batches:batches.data.data.reduce((t,n)=>t+n.quantity,0)               
                        });
                        setPageLoading(false);
                    }catch(e){
                        const meds = await Requests.getMedcine();
                        const branch = await Requests.getBranch();
                        const pharm = await Requests.getPharmacist();
                        const exp = await Requests.getBatchExpiredAdmin();
                        const outofstock =  await Requests.getBatchOutOfStockAdmin();
                        const insuredUsers = await Requests.getInsuranceUser();
                        const pendingInusredUser = await Requests.getPendingInsuranceUser();
                        const batches = await Requests.getBatch();
                        setStatData({
                            branch:branch.data.data.length,
                            pharm:pharm.data.data.length,
                            med:meds.data.data.length,
                            expired:exp.data.data.length,
                            outofStock:outofstock.data.data.length,
                            insureduser:insuredUsers.data.data.length,
                            pendingInsuredUser:pendingInusredUser.data.data.length,
                            batches:batches.data.data.reduce((t,n)=>t+n.quantity,0)               
                        });
                        setPageLoading(false);
                    }
           
            })();
        },[])
    return (
        <PageLayout loading={pageLoading} title="DashBoard">
        <StyledDashBoardAdmin> 
          <div>
          <div className='card'>
                  <StoreOutlined/>
                  <div>
                      <h3>Branch</h3>
                      <span>{statData.branch}</span>
                  </div>
              </div>
              <div className='card'>
                  <Person4Outlined/>
                  <div>
                      <h3>Pharmacist</h3>
                      <span>{statData.pharm}</span>
                  </div>
              </div>
              <div className='card'>
                  <MedicationOutlined/>
                  <div>
                      <h3>Medicines</h3>
                      <span>{statData.med}</span>
                  </div>
              </div>
              <div className='card'>
                  <DeleteSweep/>
                  <div>
                      <h3>Expired Medicine</h3>
                      <span>{statData.expired}</span>
                  </div>
              </div>
              <div className='card'>
                  <LocalShipping/>
                  <div>
                      <h3>Out of stock Medicine</h3>
                      <span>{statData.outofStock}</span>
                  </div>
              </div>

              <div className='card'>
                  <ListAltOutlined/>
                  <div>
                      <h3>Batches</h3>
                      <span>{statData.batches}</span>
                  </div>
              </div>
              <div className='card'>
                  <PermIdentityOutlined/>
                  <div>
                      <h3>Insured User</h3>
                      <span>{statData.insureduser}</span>
                  </div>
              </div>
              <div className='card'>
                  <Person/>
                  <div>
                      <h3>Applied Insured Users</h3>
                      <span>{statData.pendingInsuredUser}</span>
                  </div>
              </div>
          </div>
        </StyledDashBoardAdmin> 
              </PageLayout>
    )
    }
            