
import React, { useEffect, useState }  from 'react'
import { CustomeDropdown, CustomTable,DynamicInput,FilterAndSearch, FormInput, PageLayout,Toaster } from '../../components'
import { Requests } from '../../services/requests';
import { Edit ,Delete,Visibility, RepeatRounded} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { requirePropFactory } from '@mui/material';
import useToast from '../../custome_hook/useToast';
    import { StyledReportPres } from './ReportPres.styled'
    export default function ReportPres() {
        const list = ["Daily","Monthly","Yearly"];
        const navigate =    useNavigate();
        const [dateKey,setDateKey] = useState("");
        const [loading,setLoading] = useState(true);
        const theader = ["Prescription ID","Prescription Date","Insured User","Hospital Name","Number of Medication"]
        const [dat,setDat] = useState([])
        const [listData,setListData] = useState([]);
        const [listDatainit,setListDatainit] = useState([]);
        const [toastMes,setToastMes] = useToast();
        const [popUpIndex ,setPopUpIndex] = useState(-1);
        const [pageLoading,setPageLoading] = useState(true);
        const searchBy  = [{key:"prescription_id",name:"Prescription Id"},{key:"prescription_date",name:"Prescription Date"},{key:"hospital_name",name:"Hospital Name"}];
        const filters = [];
        const [selected,setSelected] = useState(0);
        const dateConv  = (datei,isInput = true)=>{
          const dattemp = datei.split("-");
          return isInput ? [dattemp[1],dattemp[2],dattemp[0]].join("-") : [dattemp[2],dattemp[0],dattemp[1]].join("-");     
        }
        const handleSearch = (searched)=>{
            setListData(searched)
        } 
        const handleSelected = (ind) =>{
            setSelected(ind);
        }
        const handleDate = (dated)=>{
            console.log(dated);
            setDateKey(dated)
        } 
        const actions = [
            {
                icon:Visibility,
                handler:async (ind)=>{
                   setPopUpIndex(ind)
                }
            },
        //     {
        //         icon:RepeatRounded,handler:async (ind)=>{
        //             const id = listData[ind].prescription_id;
        //             try{    
        //                 setPageLoading(true);
        //                  await Requests.rePrescribe({prescription_id:id});
        //                 const resb = await Requests.getMedcinePharm();
        //                 const resins = await Requests.getInsuranceUser();
        //                 const res = await Requests.getPrescription();
        //                 if(res.data.success){
        //                 setDat([
        //                         ...res.data.data.map((r,i)=>{
        //                             return {
        //                                  id:r._id,
        //                                  prescription_id:r.prescription_id,
        //                                  prescription_date:r. prescription_date,
        //                                  insurance_user_id:resins.data.data.find(d=>d._id === r.insurance_user_id).name,
        //                                  hospital_name: r.hospital_name,
        //                                  medicine_name:resb.data.data.find(d=>d._id === r.medicine_id).name,
        //                                  dosage: r.dosage,
        //                                  refill_date: r. refill_date,
        //                                  take_time:r.take_time,
        //                         }})
        //                     ]);
        //                     setToastMes({message:"Prescription updated",isError:false});
        //                 }else  setToastMes({message:"Prescription failed",isError:true});
        //                 setPageLoading(false);
        //             }catch(e){
        //                 console.log(e.response.data.message)
        //                 setPageLoading(false);
        //                 setToastMes({message:e.response.data.message,isError:true});
                     
        //             }
        //         }
        //     },
        //     {
        //     icon:Delete,handler:async (ind)=>{
        //         if(listDatainit[ind].isHQ) return   setToastMes({message:"Head Quarter can't be deleted",isError:true});
        //         const id = listDatainit[ind].prescription_id;
        //         try{    
        //             setListData(listDatainit.filter((l,i)=>i!=ind));
        //             await Requests.deletePrescription(id);
        //             setListDatainit(listDatainit.filter((l,i)=>i!=ind));
        //             setToastMes({message:"Deleted Successfully",isError:false});
        //         }catch(e){
        //             setToastMes({message:e.response.data.message,isError:true});
        //             console.log(e);
        //         }
        //     }
        // }
    ];
    useEffect(()=>{
        (async ()=>{
            setPageLoading(true);
            let res;
            try{
                switch(selected){
                    case 0:
                         res = await Requests.getPrescriptionDaily(dateKey);
                        break;
                    case 1:
                         res = await Requests.getPrescriptionMonthly(dateKey);
                        break;
                    case 2:
                         res = await Requests.getPrescriptionYearly(dateKey);
                        break;
                  }
                  const resb = await Requests.getMedcinePharm();
                  const resins = await Requests.getInsuranceUser();
                  if(res.data.success){
                  setDat([
                          ...res.data.data.map((r,i)=>{
                              return {
                                   id:r._id,
                                   prescription_id:r.prescription_id,
                                   prescription_date:r. prescription_date,
                                   insurance_user_id:resins.data.data.find(d=>d._id === r.insurance_user_id).name,
                                   hospital_name: r.hospital_name,
                                   medicine_name:resb.data.data.find(d=>d._id === r.medicine_id).name,
                                   dosage: r.dosage,
                                   refill_date: r. refill_date,
                                   take_time:r.take_time,
                          }})
                      ])
                  }
                  setLoading(false);
                  setPageLoading(false);        
            }catch(e){
                console.log(e);
                if(e.response)
                setToastMes({message:e.response.data.message,isError:true})
                else setToastMes({message:e.toString(),isError:true})
                setLoading(false)
            }
        })();
       console.log(dateKey,selected);
    },[dateKey,selected])
    useEffect(()=>{
        (async ()=>{
            try{
                const date = new Date();
                const formatter = new Intl.DateTimeFormat("en-GB", { 
                 year: "numeric",
                 month: "2-digit",
                 day: "2-digit",
               });
               const today = formatter.format(date);
               setDateKey(today.split("/").reverse().join("-"));
                setPageLoading(true);
                const resb = await Requests.getMedcinePharm();
                const resins = await Requests.getInsuranceUser();
                const res = await Requests.getPrescription();

                if(res.data.success){
                setDat([
                        ...res.data.data.map((r,i)=>{
                            return {
                                 id:r._id,
                                 prescription_id:r.prescription_id,
                                 prescription_date:r. prescription_date,
                                 insurance_user_id:resins.data.data.find(d=>d._id === r.insurance_user_id).name,
                                 hospital_name: r.hospital_name,
                                 medicine_name:resb.data.data.find(d=>d._id === r.medicine_id).name,
                                 dosage: r.dosage,
                                 refill_date: r. refill_date,
                                 take_time:r.take_time,
                        }})
                    ])
                }
                setLoading(false);
                setPageLoading(false);
            }catch(e){
                console.log(e);
                if(e.response)
                setToastMes({message:e.response.data.message,isError:true})
                else setToastMes({message:e.toString(),isError:true})
                setLoading(false)
            }
        })();
    },[]);
    useEffect(()=>{
        setTimeout(()=>{
          setToastMes("")
         },5000);
       },[toastMes])
       useEffect(()=>{
        setListData(listDatainit);
    },[listDatainit])
       useEffect(()=>{
        const pres = dat.map(d=>d.prescription_id).filter((c, index) => {
            return dat.map(d=>d.prescription_id).indexOf(c) === index;
        });
        setListDatainit([
            ...pres.map(b=>{
                const data = dat.filter(d=>d.prescription_id === b)
                return{
                 prescription_id:data[0].prescription_id,
                 prescription_date:data[0].prescription_date,
                 insurance_user_id:data[0].insurance_user_id,
                 hospital_name: data[0].hospital_name,
                 noOfMed:data.length,
               }})
            ])
         
       },[dat])
    return (
        <PageLayout loading={pageLoading} title='Prescription Report'>  
        <Toaster message={toastMes.message} isError={toastMes.isError} /> 
        <StyledReportPres> 
              { 
        popUpIndex < 0 ? null :  
        <div className='pop-up' onClick={()=> setPopUpIndex(-1)}  > 
       
          <div>
           
             <CustomTable   
             noAction={true} 
             theader={["Medcine Name","Dosage","Refilll Date","Take Time"]}
             actions={actions} 
             data={ dat.filter(d => d.prescription_id===listData[popUpIndex].prescription_id).map(d=>{return { medicine_name:d.medicine_name, dosage:d.dosage,refill_date:d.refill_date,take_time:d.take_time}})}/>
          </div>
       
        </div>
        }
        <div style={{display:"flex",gap:"5px"}}>
        <CustomeDropdown selected={selected} handleSelect={handleSelected} list={list} />
        <DynamicInput handleInput={handleDate} type={"date"} value={dateKey} />

        </div>
       
         {/* <FilterAndSearch searchHandler={handleSearch} data={listDatainit.map(l=>{const {isHQ,...rest} = l;return rest})} searchBy={searchBy} filters={filters} /> */}
        <CustomTable loading={loading} theader={theader} actions={actions} data={listData.map(l=>{const {id,isHQ,...rest} = l;return rest})}/>
        </StyledReportPres> 
         </PageLayout>
    )
    }
            