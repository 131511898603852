
    import React from 'react'
    import { StyledGoogleMap } from './GoogleMap.styled'
    import {Map,GoogleApiWrapper,Marker} from 'google-maps-react';
import { useState } from 'react';
    function GoogleMap({google,initialCord={
        lat: 9.0143314,
        lng:38.7573325
      }, handleCords = (lat,lng)=>{}}) {
      const [pos,setPos] =  useState(initialCord)
       const onMarkerDragEnd = (cord) =>{
            setPos({
                lat:cord.latLng.lat(),
                lng:cord.latLng.lng()
            });
            handleCords(cord.latLng.lat(),cord.latLng.lng());
        }

    return (
        
        <StyledGoogleMap> 
           <Map
             google={google}
             style={{width:"100%"}}
             zoom={20}
             onClick={(t, map, coord) =>onMarkerDragEnd(coord)}
             initialCenter={
                {
                    lat: 9.0143314,
                    lng:38.7573325
                }
             }
           >
            <Marker
             position={pos}
            //  draggable={true}
            //  onDragend={(t, map, coord) =>onMarkerDragEnd(coord, 0)}
            //  onClick={(t, map, coord) =>onMarkerDragEnd(coord, 0)}
            //  onClick={(e) => {
            //     console.log(e);
            //     // console.log("latitude = ", e.lat)
            //     // console.log("longtitude = ", e.lng);
            // }}
                name={'Current location'} />
           </Map>
        </StyledGoogleMap> 
    )
    }
export default GoogleApiWrapper({
  apiKey: "AIzaSyClzHIl_aCZ-eNg__5XG2TTJBbFhzfTx9Y"
})(GoogleMap)
