import { createGlobalStyle } from "styled-components";
import Themes from "./theme";
const Global = createGlobalStyle`
@font-face {
    font-family: "input";
    font-weight: 100;
    src: local("Segoe UI Light");
}
 *{
     margin:0;
     padding:0;
     box-sizing:border-box !important;
 }
 body,html,#root{
     width:100%;
     min-height:100vh !important;
     background-color:white;
     font-family:Segoe UI;
     margin: auto;
     display: flex;
     flex-direction: column;
     scroll-behavior: smooth;
     overflow:hidden ;
 }

`;
export default Global;   