
    import styled from "styled-components";
    import Themes from "../../style/theme";
    export const StyledFormBtn = styled.button`
            width: auto;
            margin:auto;
            padding:10px;
            border-radius: 5px;
            border:none;
            outline:0;
            background-color: ${Themes.primaryGreen};
            color:${Themes.white};
            transition: background-color 0.5s;
            margin-bottom: 10px;
            margin-top: 10px;
            text-align:center ;
            font-size:1.2em;
            font-family: 'Nunito Sans','Helvetica Neue',Helvetica,Arial,sans-serif !important;
            &.full{
             width:100%;
            }
            &.admin-btn{
                background-color:${Themes.niceBlue};
            }
            img{
                width:90%;
                max-width:100px;
            }
            &:hover{
                background-color:${Themes.primaryGreenDark};
                cursor: pointer;
            }
            &.disabled{
                cursor:not-allowed ;
            }

    `
            