
    import styled from "styled-components";
import Themes from "../../style/theme";
    export const StyledFileInput = styled.div`
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    justify-content:flex-start ;
    & > span{
      height:40px;
      margin-bottom:5px;
    }
    .error{
      padding:10px;
      color:${Themes.redLight}
    }
    & > button{
      display: flex;
      justify-content: flex-start;
      margin:0;
    }
      img{
        width:100px;
      }
    `
            