import "./App.css";

import {BrowserRouter as Router, Routes, Route,Navigate } from "react-router-dom";
import {AccountCircle,ArrowDropDown,HomeOutlined,MedicationOutlined,PermIdentityOutlined,Person4Outlined,StoreOutlined,AssessmentOutlined,ListAltOutlined, SignalWifiStatusbarNullOutlined, ReceiptLong, ReceiptLongOutlined} from "@mui/icons-material";

import {AddBatch,DashBoardAdmin,DashBoardPharm, ListPendingAdmin,EditMedicine,ListMedcine,AddPharmacist,EditPharmacist,ListPharmacist, EditBranch,ListBranch,AddBranch,AddMedicine,Login, EditAccount, AddInsured, ListInsured, EditInsured, AddPescription, ListPescription, ListBatch, ViewOutofStock, ViewExpired, ListPendingPharm, ListBatchPharm, ListNotify, AddNotify, Register, LandingPage, RegisteredMessage, ReportPres} from "./screens";
import {DashboardLayout, LandingNavbar, Toaster} from "./components";
import {useSelector} from "react-redux";
import Global from "./style/Globals";
import { useEffect,useState } from "react";
import { Requests } from "./services/requests";
import {useDispatch} from "react-redux";
import {setUser} from "./redux/reducers/user.reducer";
import loader from "./loading.svg";
import ListPendingUsers from "./screens/ListPendingUsers/ListPendingUsers.screen";
function App() {
  const [appLoading ,setAppLoading] = useState(true);
  const dispatch = useDispatch();
  const user  = useSelector((state) => state.user.user);
  const admin_navs = [
    {
        icon:HomeOutlined,
        title:"Dashboard",
        isActive:false,
        route:"/",
        subList:[]
    },
    {
        icon:StoreOutlined,
        title:"Branch",
        isActive:false,
        subList:[
           {name:"Add Branch",route:"/add_branch"},
           {name:"List Branch",route:"/list_branch"}
        ]
    },
    {
        icon:MedicationOutlined,
        title:"Medicine",
        isActive:false,
        subList:[
           {name:"Add Medicine",route:"/add_medcine"},
           {name:"List Medicine",route:"/list_medcine"}
        ]
    },
    
    {
        icon:Person4Outlined,
        title:"Pharmacist",
        isActive:false,
        subList:[
           {name:"Add Pharmacist",route:"/add_pharmacist"},
           {name:"List Pharmacist",route:"/list_pharmacist"}
        ]
    },
    {
      icon:PermIdentityOutlined,
      title:"Insured User",
      isActive:false,
      subList:[
          {name:"List Insured User",route:"/list_insured"},
          {name:"List Applied",route:"/list_pending_user"},
      ]
  },
  {
    icon:ListAltOutlined,
    title:"Batch",
    isActive:false,
    subList:[
       {name:"Add Batch",route:"/add_batch"},
       {name:"List Batch",route:"/list_batch"},
       {name:"List Distributed",route:"/list_pending"}
    ]
},
    {
        icon:AssessmentOutlined,
        title:"Report",
        isActive:false,
        subList:[
            {name:"Out of stock",route:"/outofstock"},
            {name:"Expired",route:"/expired"}
        ]
    }
];
const pharms_navs = [
  {
    icon:HomeOutlined,
    title:"Dashboard",
    isActive:false,
    route:"/",
    subList:[]
},
  
    
    {
      icon:ReceiptLongOutlined,
      title:"Prescription",
      isActive:false,
      subList:[
          {name:"Add Prescription",route:"/add_prescription"},
          {name:"List Prescription",route:"/list_prescription"},
      ]
  },
  {
    icon:ListAltOutlined,
    title:"Batch",
    isActive:false,
    subList:[
       {name:"List Batch",route:"/list_batch"},
       {name:"List Pending",route:"/list_pending"}
    ]
},
    {
      icon:AssessmentOutlined,
      title:"Report",
      isActive:false,
      subList:[
        {name:"Out of stock",route:"/outofstock"},
        {name:"Expired",route:"/expired"}
      ]
  }
]
  useEffect(()=>{
    (async ()=>{
       try{
         const res = await Requests.getUser();
         const data = res.data;
         dispatch(setUser({
          username: data.uname,
          name:data.name,
          profile:data.profile,
          isAdmin:data.isAdmin,
          role:data.role
        }));
        setAppLoading(false);
       }catch(e){
          setAppLoading(false);
          dispatch(setUser(null));
       }
    })();
  },[]);
  return (
    <>
       
    {appLoading ?  <img  style={{display:"block",margin:"auto"}} src={loader} width="50px" /> : <>{
      user ? user.isAdmin ?
      <Router>
      <Global />
      <DashboardLayout navs={admin_navs}>
      <Routes>
          <Route path="/" exact element={<DashBoardAdmin />} />
          <Route path="/account" exact element={<EditAccount />} />
          <Route path="/add_branch" exact element={<AddBranch />} />
          <Route path="/list_branch" exact element={<ListBranch />} />
          <Route path="/edit_branch/:id" exact element={<EditBranch />} />
          <Route path="/add_pharmacist" exact element={<AddPharmacist />} />
          <Route path="/list_pharmacist" exact element={<ListPharmacist />} />
          <Route path="/edit_pharmacist/:id" exact element={<EditPharmacist />} />
          <Route path="/add_medcine" exact element={<AddMedicine />} />
          <Route path="/list_insured" exact element={<ListInsured />} />
          <Route path="/list_pending_user" exact element={<ListPendingUsers />} />
          <Route path="/edit_insured/:id" exact element={<EditInsured />} />
          <Route path="/add_batch" exact element={<AddBatch />} />
          <Route path="/list_batch" exact element={<ListBatch />} />
          <Route path="/list_pending" exact element={<ListPendingAdmin />} />
          <Route path="/list_medcine" exact element={<ListMedcine />} />
          <Route path="/edit_medcine/:id" exact element={<EditMedicine />} />
          <Route path="/outofstock" exact element={<ViewOutofStock />} />
          <Route path="/notify" exact element={<ListNotify />} />
          <Route path="/expired" exact element={<ViewExpired />} />
          <Route path="*" element={<Navigate to="/" replace />}/>
      </Routes>
      </DashboardLayout>
    </Router> : 
    <Router>
           <Global />
      <DashboardLayout navs={pharms_navs}>
        <Routes>
          <Route path="/" exact element={<DashBoardPharm />} />
          <Route path="/add_prescription" exact element={<AddPescription />} />
          <Route path="/list_prescription" exact element={<ListPescription />} />
          <Route path="/edit_prescription/:id" exact element={<EditInsured />} />
          <Route path="/outofstock" exact element={<ViewOutofStock />} />
          <Route path="/list_batch" exact element={<ListBatchPharm />} />
          <Route path="/list_pending" exact element={<ListPendingPharm />} />
          <Route path="/expired" exact element={<ViewExpired />} />
          <Route path="/account" exact element={<EditAccount />} />
           
          <Route path="/presreport" exact element={<ReportPres />} />
          <Route path="/notify" exact element={<AddNotify />} />
          <Route path="*" element={<Navigate to="/" replace />}/>
        </Routes>
      </DashboardLayout>
     </Router>:     
    <Router>
        <Global />
        <div style={{height:"100vh",overflow:"auto"}}>
        <LandingNavbar />
        <Routes>
        <Route path="/" exact element={<LandingPage />} />
          <Route path="/registered" exact element={<RegisteredMessage />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/register" exact element={<Register />} />
          <Route path="*" element={<Navigate to="/" replace />}/>
        </Routes>
        </div>
      
     </Router>
    }</>} 
        </>
  );
}

export default App;
