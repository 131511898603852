
    import React, { useEffect, useState }  from 'react'
    import { CustomTable,FilterAndSearch, PageLayout,Toaster } from '../../components'
    import { Requests } from '../../services/requests';
    import { Edit ,Delete, Visibility, Cancel, CheckRounded} from '@mui/icons-material';
    import { useNavigate } from 'react-router-dom';
    import { batch } from 'react-redux';
    import { StyledListPendingPharm } from './ListPendingPharm.styled'
import useToast from '../../custome_hook/useToast';
    export default function ListPendingPharm() {
        const navigate =    useNavigate();
        const [loading,setLoading] = useState(true);
        const theader = ["Medicine","No of Medicine"]
        const [listData,setListData] = useState([]);
        const [listDatainit,setListDatainit] = useState([]);
        const [toastMes,setToastMes] = useToast();
        const [popUpIndex ,setPopUpIndex] = useState(-1);
        const [pageLoading,setPageLoading] = useState(true);
        const [dat,setDat] = useState([])
        const searchBy  = [{key:"batch",name:"Medicine Name"}];
        const filters = [];
        const headerActions = [
            {icon:CheckRounded,name:"Confirm Delivery",handler:async ()=>{
               if(dat.length <= 0) return;
               setPageLoading(true);
               try{
                  await Requests.acceptPending();
                  setToastMes({message:"Delivery Confirmed",isError:false});
                  setDat([]);
               }catch(e){
                      setToastMes({message:e.response.data.message,isError:true}) 
               }
               setPageLoading(false);
            }}
       ];
        const handleSearch = (searched)=>{
            setListData(searched)
        }
        const actions = [
            {
                icon:Visibility,
                handler:async (ind)=>{
                   setPopUpIndex(ind)
                }
            }
    ];
    useEffect(()=>{
        (async ()=>{
            try{
                setPageLoading(true);
                const res = await Requests.getPendingPharm();
             
                const res2 = await Requests.getMedcinePharm();
               
                const res3 = await Requests.getBranchPharm();
                console.log("done")
                if(res.data.success){
                    const dat = res.data.data.map(r=>{return {
                        medicine:res2.data.data.find(d=>d._id == r.medicine_id).name+", "+res2.data.data.find(d=>d._id == r.medicine_id).generic_name,
                        quantity: r.quantity,
                        branch:res3.data.data.find(d=>d._id == r.branch_id).name,
                        date_expire:r.date_expire,
                        date_mfg: r.date_mfg,
                    }});
                    console.log(dat)
                    setDat([...dat]);
             
                }
                setLoading(false);
                setPageLoading(false);
              
            }catch(e){
                console.log("ITS FAILED");
                if(e.response)
                setToastMes({message:e.response.data.message,isError:true})
                else   setToastMes({message:e.toString(),isError:true})
                setLoading(false)
                setPageLoading(false)
            }
        })();
    },[]);
    useEffect(()=>{
        setTimeout(()=>{
          setToastMes("")
         },5000);
       },[toastMes])
       useEffect(()=>{
        setListData(listDatainit);
    },[listDatainit])
       useEffect(()=>{
        console.log(dat);
        const batches = dat.map(d=>d.medicine).filter((c, index) => {
            return dat.map(d=>d.medicine).indexOf(c) === index;
        });
    setListDatainit([
        ...batches.map(b=>{
            return{
             batch:b,
             noOfMed:dat.filter(d=>d.medicine==b).reduce((tot,num)=>tot+num.quantity,0)
           }})
        ])
        console.log("room",listData);
       },[dat])
    return (
        <PageLayout actions={dat.length > 0 ? headerActions : []}  loading={pageLoading} title='Pending Medicine List'>  
        <Toaster message={toastMes.message} isError={toastMes.isError} />  
        <StyledListPendingPharm> 
        { 
        popUpIndex < 0 ? null :  
        <div className='pop-up' onClick={()=> setPopUpIndex(-1)}  > 
          <div>
             <CustomTable   noAction={true} theader={["Medcine","Quantity","Branch","Date of Expired","Date of Manufacture"]} actions={actions} data={ dat.filter(d => d.medicine===listData[popUpIndex].batch)}/>
          </div>
        </div>
        }
        <FilterAndSearch searchHandler={handleSearch} data={listDatainit.map(l=>{const {isHQ,...rest} = l;return rest})} searchBy={searchBy} filters={filters} />
        <CustomTable loading={loading} theader={theader} actions={actions} data={listData.map(l=>{const {id,isHQ,...rest} = l;return rest})}/>
        </StyledListPendingPharm> 
        </PageLayout>
    )
    }
            