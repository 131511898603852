import styled from "styled-components";
import Themes from "../../style/theme";
export const StyledDashboardHeader = styled.div`
     @keyframes show-account{from{margin-top:100px;}to{margin-top:50px;}}
     display:flex;
     justify-content:space-between;
     background-color:${Themes.white};
     align-items:center ;
     box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
     padding:5px;
     & > svg{
        margin:10px;
        cursor: pointer;
        color:${Themes.niceGreen};
     }
     .header-actions-cont{
         display:flex;
         margin-right:10px;
     }
     .header-actions{
        width:40px;
        aspect-ratio:1/1;
        border:1px solid ${Themes.lightgray};
        border-radius:5px;
        margin:5px;
        display:flex;
        justify-content:center;
        align-items:center;
        &:hover{
            background-color:${Themes.niceGrey};
            cursor:pointer;
        }
        &>span{
            height: 18px;
            width: 20px;
            font-size: .59375rem;
            position: absolute;
            margin-left:42px;
            margin-top:-32px;
            background-color: ${Themes.niceRed};
            display: block;
            color: #fff;
            border-radius: 50%;
            line-height: 18px;
            text-align: center;
            z-index:1;

            
        }
        & > svg{
            cursor: pointer;
        }
        .dropdown-account{
            position:absolute;
            top:0;
            right:0;
            margin-top:50px;
            margin-right:5px;
            width:100%;
            max-width:250px;
            background-color:${Themes.white};
            border-radius:0px 0px 5px 5px;
            padding:20px;
            box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
            display:none;
            z-index:9999;
            &.show{
                display:block;
                animation: show-account forwards 0.3s;
            }
            & > div:first-child{
                display:flex;
                flex-direction:column;
                align-items:center ;
                margin:20px 0px;
                font-family: 'Nunito Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
                img{
                    border-radius:50%;
                    width:100px;
                    aspect-ratio:1/1;
                    margin-bottom:20px ;
                }
                h6{
                    font-weight: 700;
                    color: ${Themes.fadeBlack};
                    margin-bottom: 0;
                    line-height: 1.2;
                    font-size: 18px;    
                    font-family:'Nunito Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
                }
                span{
            
                    display: block;
                    font-size: 13px;
                    color: ${Themes.darkGrey};
                    font-family: 'Nunito Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
                }
            }
            & > div:last-child{
                font-family:'Nunito Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
                display:flex;
                flex-direction:column;
                *{
                    font-family:'Nunito Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
                }
                & > div,& > div > a{
                    text-decoration:none ;
                    font-size: 13px;
                    color: ${Themes.veryDarkGrey};
                    cursor:pointer ;
                    font-weight: 600;
                    height: 38px;
                    display:flex;
                    align-items:center;
                    border-bottom:1px dotted ${Themes.inputBg};
                    padding:5px;
                    font-weight:100 ;
                    letter-spacing:1px ;
                    span{
                        a{
                        text-decoration: none;
                        font-family: 'Nunito Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
                    }
                        
                    }
                   
                    &:last-child{
                       border:none;
                    }
                    &:hover svg,&:hover span,&:hover a{
                        color: ${Themes.niceGreen};
                    }
                    svg{
                        font-size: 22px;
                        text-align: center;
                        margin-right: 10px;
                        width: 24px;
                    }
    
                }
            }
           
        }
     }
    `
            