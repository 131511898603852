
import React, { useEffect, useState } from 'react'
import { useNavigate ,useParams} from 'react-router-dom';
import { Toaster} from '../../components'
import { Requests } from '../../services/requests';
import validator,{nameFieldValidator,phoneValidator, emptyFieldValidator} from "../../services/validator";
import { CustomeDropdown, DynamicInput, FormBtn, GoogleMap, PageLayout } from '../../components'
import { StyledEditBranch } from './EditBranch.styled'
import useToast from '../../custome_hook/useToast';
    export default function EditBranch() {
        const list = ["Active","Inactive"];
        const [isFetching,setIsFetching] = useState(true);
        const [initialCord,setInitialCord] =useState({
            lat: 9.0143314,
            lng:38.7573325
          })
        const {id} = useParams();
        const [name,setName] = useState({value:"",isValid:"false",error:""});
        const [phone,setPhone] = useState({value:"",isValid:"false",error:""});
        const [address,setAddress] = useState({value:"",isValid:"false",error:""});
        const [wereda,setWereda] = useState({value:"",isValid:"false",error:""});
        const [housenum,setHousenum] = useState({value:"",isValid:"false",error:""});
        const [selected,setSelected] = useState(null);
        const [toastMes,setToastMes] = useToast();
        const [selectError,setSelectError] = useState("");
        const [pageLoading,setPageLoading] = useState(true);
        const [loading ,setLoading] = useState(false);
        const [pos,setPos] =  useState({
          lat: 9.0143314,
          lng:38.7573325
        })
        const navigate = useNavigate();
        const handleSelected = (ind) =>{
            setSelected(ind);
            setSelectError("");
        }
        const handleName = (val)=>{
            const {message,result} = validator(val ,[emptyFieldValidator, nameFieldValidator]);
            setName({value:val,isValid:result,error:message});
         }
         const handlePhone = (val)=>{
            const {message,result} = validator(val ,[phoneValidator]);
            setPhone({value:val,isValid:result,error:message});
         }
         const handleAddress = (val)=>{
            const {message,result} = validator(val ,[emptyFieldValidator]);
            setAddress({value:val,isValid:result,error:message});
         }
         const handleCords = (lat,lng)=>{
          setPos({lat,lng});
         }
         const handleHousenum = (val)=>{
          const {message,result} = validator(val ,[emptyFieldValidator]);
          setAddress({value:val,isValid:result,error:message});
       }
       const handleWereda = (val)=>{
        const {message,result} = validator(val ,[emptyFieldValidator]);
        setAddress({value:val,isValid:result,error:message});
     }
         useEffect(()=>{
            setTimeout(()=>{
              setToastMes("")
             },5000);
           },[toastMes]);
           const handleSubmit = async (e)=>{
              e.preventDefault();
              if(pos.lat&&pos.lng&&name.isValid&&phone.isValid&&address.isValid&&wereda.isValid&&housenum.isValid&&selected!=null){
                setLoading(true);
                try{ 
                   const res = await Requests.updateBranch(id,{
                    name:name.value,
                    phone: phone.value,
                    address: address.value,
                    wereda:wereda.value,
                    housenumber:housenum.value,
                    location: {
                      longitude:pos.lng,
                      latitude: pos.lat
                    },
                    isActive: selected === 0 ? true : false
                  });
                   if(res.data.success)
                    navigate("/list_branch",{state: {message: 'Branch updated Successfully', isError: false }})
                  else setLoading(false);
                }catch(e){
                  setToastMes({message:e.response.data.message,isError:true});
                  setLoading(false);
                }
              }else{
                if(selected == null) setSelectError("Select is required");
                handlePhone(phone.value);
                handleAddress(address.value);
                handleName(name.value);
              }
           };
           useEffect(()=>{
            (async ()=>{
                
                try{
                  setPageLoading(true);
                    const res = await Requests.getBranchById(id);
                    if(res.data.success){
                        setName({value:res.data.data.name,isValid:true,error:""});
                        setPhone({value:res.data.data.phone,isValid:true,error:""});
                        setAddress({value:res.data.data.address,isValid:true,error:""});
                        setSelected(res.data.data.isActive ? 0 : 1);
                        setWereda({value:res.data.data.wereda ?? "",isValid:true,error:""});
                        setHousenum({value:res.data.data.housenumber ?? "",isValid:true,error:""});
                        setInitialCord({
                            lng:Number(res.data.data.location.longitude),
                            lat:Number(res.data.data.location.latitude)
                        });
                        setPos({
                            lng:Number(res.data.location.longitude),
                            lat:Number(res.data.location.latitude)
                        });
                    }   
                    setIsFetching(false);
                    setPageLoading(false);
                }catch(e){
                    
                    if(e.response)
                    console.log(e.response.data);
                    else console.log(e);
                    setIsFetching(false);
                    setPageLoading(false);
                }
            })();
           },[])
    return (
        <PageLayout loading={pageLoading} title="Edit Branch">
             <Toaster message={toastMes.message} isError={toastMes.isError} />
       {!isFetching ? <StyledEditBranch>   
        <form onSubmit={handleSubmit}>
        <div>
                <DynamicInput value={name.value} label="Branch Name"  type="text" required={true} placeholder="Enter branch name" disabled={loading} error={name.error} handleInput={handleName}/>
                <DynamicInput value={phone.value} label="Branch Phone"  type="text" required={true} placeholder="Enter branch phone" disabled={loading} error={phone.error} handleInput={handlePhone}/>
                <DynamicInput value={address.value} label="Branch Address"  type="text" required={true} placeholder="Enter branch address" disabled={loading} error={address.error} handleInput={handleAddress}/>
                <DynamicInput value={wereda.value} label="Branch Wereda"  type="text" required={true} placeholder="Enter branch wereda" disabled={loading} error={wereda.error} handleInput={handleWereda}/>
                <DynamicInput value={housenum.value} label="Branch House number"  type="text" required={true} placeholder="Enter branch house number" disabled={loading} error={housenum.error} handleInput={handleHousenum}/>
<GoogleMap initialCord={initialCord} handleCords={handleCords} />
    <CustomeDropdown error={selectError} searchRequired={false} loading = {loading} label="Status" required={true} selected={selected} handleSelect={handleSelected}  placeholder="Select..." list={list}/>

        </div>
                <FormBtn isFullWidth={false} disabled={loading} label="Update" />
            </form>
        </StyledEditBranch> : <span>Loading...</span> }
        </PageLayout>
    )
    }
            