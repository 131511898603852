
    import { CheckBoxOutlineBlank, CheckBoxRounded, CheckCircleOutlineRounded, CheckRounded } from '@mui/icons-material'
import React from 'react'
    import { StyledRegisteredMessage } from './RegisteredMessage.styled'
    export default function RegisteredMessage() {
    return (
        <StyledRegisteredMessage> 
            <CheckCircleOutlineRounded/>
            <h1>Thank your for registering</h1>
        </StyledRegisteredMessage> 
    )
    }
            