import React, { useEffect, useState }  from 'react'
import { CustomTable,FilterAndSearch, PageLayout,Toaster } from '../../components'
import { Requests } from '../../services/requests';
import { Edit ,Delete} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { StyledListPharmacist } from './ListPharmacist.styled'
import useToast from '../../custome_hook/useToast';
export default function ListPharmacist() {
        const navigate =    useNavigate();
        const [loading,setLoading] = useState(true);
        const theader = ["Name","Username","Branch","status"]
        const [listData,setListData] = useState([]);
        const [listDatainit,setListDatainit] = useState([]);
        const [toastMes,setToastMes] = useToast();
        const [pageLoading,setPageLoading] = useState(true);
        const searchBy  = [{key:"name",name:"Name"},{key:"username",name:"Userame"},{key:"branchid",name:"Branch"}];
        const filters = [{key:"isActive",name:"Status",list:["Active","Inactive"]}];
        const handleSearch = (searched)=>{
            setListData(searched)
        }
        const actions = [
            {
                icon:Edit,handler:(ind)=>{
                navigate(`/edit_pharmacist/${listData[ind].id}`)
                }
            },
            {
            icon:Delete,handler:async (ind)=>{
                if(listData[ind].isHQ) return   setToastMes({message:"Head Quarter can't be deleted",isError:true});
                const id = listData[ind].id;
                try{    
                    setListData(listData.filter((l,i)=>i!=ind));
                   const res = await Requests.deletePharmacist(id);
                   setListDatainit(listData.filter((l,i)=>i!=ind));
                   setToastMes({message:"Deleted Successfully",isError:false});
                }catch(e){
                    setToastMes({message:e.response.data.message,isError:true});
                    console.log(e);
                }
            }
        }
    ];
    useEffect(()=>{
        setListData(listDatainit);
    },[listDatainit])
    useEffect(()=>{
        (async ()=>{
            try{
                setPageLoading(true);
                const res = await Requests.getPharmacist();
                const res2 = await Requests.getBranch();
                if(res.data.success){
                    setListDatainit([...res.data.data.map(d=>{
                    const branch =  res2.data.data.filter(x =>{
                            return d.branch_id === x._id
                        })[0];
                        return {
                            id:d._id,
                            name:d.name,
                            username: d.uname,
                            branchid:branch && branch.name,
                            isActive: d.isActive ? "Active" : "Inactive"
                    }})])
                }
                setLoading(false);
                setPageLoading(false);
            }catch(e){
                console.log(e);
                setLoading(false);
                setPageLoading(false);
            }
        })();
    },[]);
    useEffect(()=>{
        setTimeout(()=>{
          setToastMes("")
         },5000);
       },[toastMes])
    return (
        <PageLayout loading={pageLoading} title='List Pharmacist'>  
        <Toaster message={toastMes.message} isError={toastMes.isError} />  
        <StyledListPharmacist> 
        <FilterAndSearch searchHandler={handleSearch} data={listDatainit.map(l=>{const {isHQ,...rest} = l;return rest})} searchBy={searchBy} filters={filters} />
        <CustomTable loading={loading} theader={theader} actions={actions} data={listData.map(l=>{const {id,isHQ,...rest} = l;return rest})}/>
        </StyledListPharmacist>
        </PageLayout>  
    )
    }
            