import React, { useEffect, useState }  from 'react'
import { CustomTable, PageLayout,Toaster } from '../../components'
import { Requests } from '../../services/requests';
import { Edit ,Delete, DeleteForever} from '@mui/icons-material';
import {useSelector} from "react-redux";
import { useNavigate } from 'react-router-dom';
    import { StyledViewExpired } from './ViewExpired.styled'
    export default function ViewExpired() {
        const navigate =    useNavigate();
        const user  = useSelector((state) => state.user.user);
        const [loading,setLoading] = useState(true);
        const theader = ["Medicine Name","Generic Name"]
        const [listData,setListData] = useState([]);
        const [pageLoading,setPageLoading] = useState(true);
        const [toastMes,setToastMes] = useState({message:"",isError:false});
        const headerActions = [
            {icon:DeleteForever,name:"dispose expired",handler:async ()=>{
               setPageLoading(true);
               try{
                  if(listData.length > 0){
                    if(user.isAdmin)
                    await Requests.disposeBatchExpired();
                    else   Requests.disposeExpiredPharm();
                    setToastMes({message:"Expired medicines disposed",isError:false})
                    setListData([]);
                  }
               }catch(e){
                   setPageLoading(false);
                   setToastMes({message:e.response.data.message,isError:true}) 
               }
               setPageLoading(false);
            }}
       ];
    useEffect(()=>{
        (async ()=>{
            try{
                setPageLoading(true);
                if(!user.isAdmin){
                    const res = await Requests.getBatchExpiredPharm();
                    if(res.data.success){
                        setListData(res.data.data)
                    }
                }else{
                    const res = await Requests.getBatchExpiredAdmin();
                    if(res.data.success){
                        setListData(res.data.data)
                    }
                }
                setLoading(false);
                setPageLoading(false);
            }catch(e){
                console.log(e);
                setLoading(false)
                setPageLoading(false);
            }
        })();
    },[]);
    useEffect(()=>{
        setTimeout(()=>{
          setToastMes("")
         },5000);
       },[toastMes])
    return (
        <PageLayout actions={listData.length > 0 ? headerActions : []} loading={pageLoading} title='Expired list'>  
        <Toaster message={toastMes.message} isError={toastMes.isError} /> 
        <StyledViewExpired> 
            <CustomTable loading={loading} theader={theader} noAction={true}  data={listData.map(l=>{const {id,isHQ,...rest} = l;return rest})}/>
        </StyledViewExpired> 
             </PageLayout>  
    )
    }
            