
    import styled from "styled-components";
import Themes from "../../style/theme";
    export const StyledDynamicInput = styled.div`
   display: flex;
   flex-direction: column;
   margin-bottom: 10px;

   & > div{
    display:flex;
    flex-direction: column;
    align-items: stretch;
    gap:5px;
    & > div{
      display: flex;
      height:40px;
      box-sizing:border-box ;
    }
   }
   h5,span{
    font-family:'Nunito Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
    font-size:18px;
    padding:5px;
   }
   h5{
    color:${Themes.darkGrey};
    font-weight:100;
   }
   input{
    font-family:'Nunito Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
       padding: 12px 20px;
       outline: 0;
       border:1px solid black;
       border-radius: 5px;
       color:${Themes.fadeBlack};
       font-size: 14px;
       flex-grow:1;
       border: 1px solid #e4e5e7;
       padding:6 12;
       &:focus{
          box-shadow:0px 0px 3px ${Themes.niceGreen};
       }
       &.error{
        box-shadow:0px 0px 3px ${Themes.redLight};
       }

   }
   span{
       margin-top:10px;
         
       font-size: 0.9rem;
       color:${Themes.redLight};
   }
   @media (max-width:765px){
      & > div{
          flex-wrap:wrap ;
      }
      input{
        width:100%;
      }
   }
    `
            