import React , { useEffect, useState } from 'react'
import { useNavigate,useParams } from 'react-router-dom';
import { Toaster} from '../../components'
import { Requests } from '../../services/requests';
import validator,{nameFieldValidator, emptyFieldValidator, passwordValidator} from "../../services/validator";
import { CustomeDropdown,FileInput,DynamicInput, FormBtn, GoogleMap, PageLayout } from '../../components'
    import { StyledEditPharmacist } from './EditPharmacist.styled'
import useToast from '../../custome_hook/useToast';
    export default function EditPharmacist() {
        const [list,setList] = useState([]);
        const listStat = ["Active","Inactive"];
        const {id} = useParams();
        const [name,setName] = useState({value:"",isValid:"false",error:""});
        const [username,setUsername] = useState({value:"",isValid:"false",error:""});
        const [password,setPassword] = useState({value:"",isValid:"false",error:""});
        const [confirmPassword,setConfirmPassword] = useState({value:"",isValid:"false",error:""});
        const [selected,setSelected] = useState(null);
        const [selectedStat,setSelectedStat] = useState(null);
        const [toastMes,setToastMes] = useToast();
        const [selectError,setSelectError] = useState("");
        const [selectStatError,setSelectStatError] = useState("");
        const [loading ,setLoading] = useState(false);
        const [pageLoading,setPageLoading] = useState(true);
        const [img,setImg] = useState(null);
        const [imgError,setImgError] = useState("");
        const navigate = useNavigate();
        const handleSelected = (ind) =>{
            setSelected(ind);
            setSelectError("");
        }
        const handleStatSelected = (ind) =>{
            setSelectedStat(ind);
            setSelectStatError("");
        }
        const handleName = (val)=>{
            const {message,result} = validator(val ,[emptyFieldValidator, nameFieldValidator]);
            setName({value:val,isValid:result,error:message});
         }
         const handleUsername = (val)=>{
            const {message,result} = validator(val ,[emptyFieldValidator]);
            setUsername({value:val,isValid:result,error:message});
         }
         const handlePassword = (val)=>{
            if(val.trim()==="")   setPassword({value:"",isValid:true,error:""});
            else{
                const {message,result} = validator(val ,[passwordValidator]);
                setPassword({value:val,isValid:result,error:message});
            }
         }
         const handleConfirmPassword = (val)=>{
            const {message,result} = validator(val ,[]);
            if(val==password.value)  setConfirmPassword({value:val,isValid:result,error:message});
            else setConfirmPassword({value:val,isValid:false,error:"Password mismatch"});
         }
         useEffect(()=>{
            setTimeout(()=>{
              setToastMes("")
             },5000);
           },[toastMes]);
           const handleSubmit = async (e)=>{
              e.preventDefault();
              if(name.isValid&&password.isValid&&name.isValid&&confirmPassword.isValid&&username.isValid&&selected!=null&&img!==null&&selectedStat!==null){
                setLoading(true);
                try{ 
                   const res = await Requests.updatePharmacist(id,{
                    name:name.value,
                    username: username.value,
                    password: password.value,
                    branchid: list[selected]._id,
                    image:img,
                    isActive: selectedStat === 0 ? true : false
                  });
                   if(res.data.success)
                    navigate("/list_pharmacist",{state: {message: 'Pharmacist updated Successfully', isError: false }})
                  else setLoading(false);
                }catch(e){
                  setToastMes({message:e.response.data.message,isError:true});
                  setLoading(false);
                }
                setLoading(false);
              }else{
                if(selected == null) setSelectError("Select is required");
                if(img == null) setImgError("this field is required");
                handleName(name.value);
                handleUsername(username.value);
                handlePassword(password.value);
                handleConfirmPassword(confirmPassword.value);
              }
           };
        const handleFileChange = (data)=>{
                 setImg(data);
           }
           useEffect(()=>{
            (async ()=>{
             setPageLoading(true);
            const resp = await Requests.getPharmacistById(id);
            if(resp.data.success){
                 setName({value:resp.data.data.name,isValid:true,error:""});
                 setUsername({value:resp.data.data.uname,isValid:true,error:""});
                 list.forEach((l,i) => {
                    console.log(resp.data.data.branch_id === l._id);
                    if(resp.data.data.branch_id === l._id)
                    setSelected(i);
                }); 
               setSelectedStat(resp.data.data.isActive ? 0 : 1);
               if(resp.data.data.image)
               setImg(resp.data.data.image.url) 
            }
            setPageLoading(false);
        })();
           },[list])
           useEffect(()=>{
            (async ()=>{
                try{
                    setPageLoading(true);
                    const res = await Requests.getBranch();
                    if(res.data.success){
                        setList(res.data.data);
                    }
                    setLoading(false);
                    setPageLoading(false);
                }catch(e){
                    console.log(e.response.data);
                    setLoading(false)
                    setPageLoading(false);
                }
            })();
        },[]);
    return (
        <PageLayout loading={pageLoading} title="Edit Pharmacist">
             <Toaster message={toastMes.message} isError={toastMes.isError} />
        <StyledEditPharmacist> 
        <form onSubmit={handleSubmit}>
             <div>
             <DynamicInput value={name.value} label="Name"  type="text" required={true} placeholder="Enter name" disabled={loading} error={name.error} handleInput={handleName}/>
                <DynamicInput value={username.value} label="Username"  type="text" required={true} placeholder="Enter username" disabled={loading} error={username.error} handleInput={handleUsername}/>
                <DynamicInput value={password.value} label="Password"  type="password" required={false} placeholder="Enter password" disabled={loading} error={password.error} handleInput={handlePassword}/>
                <DynamicInput value={confirmPassword.value} label="Confirm Password"  type="password" required={false} placeholder="Enter password again" disabled={loading} error={confirmPassword.error} handleInput={handleConfirmPassword}/>
                <CustomeDropdown error={selectError} searchRequired={true} loading = {loading} label="Branch" required={true} selected={selected} handleSelect={handleSelected}  placeholder="Select..." list={list.map(d=>d.name)}/>
                <CustomeDropdown error={selectStatError} searchRequired={false} loading = {loading} label="Status" required={true} selected={selectedStat} handleSelect={handleStatSelected}  placeholder="Select..." list={listStat}/>
                <FileInput required={false} error={imgError}  src={img} handleChange={handleFileChange} />
             </div>
                <FormBtn isFullWidth={false} disabled={loading} label="Update" />
            </form>
        </StyledEditPharmacist> 
        </PageLayout>
    )
    }
            