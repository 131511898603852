
    import styled from "styled-components";
import Themes from "../../style/theme";
    export const StyledCustomTable = styled.div`
      width:100%;
      overflow: auto;
      display: flex;
      flex-direction:column ;
      margin:auto;
      & > center{
        font-family: 'Nunito Sans','Helvetica Neue',Helvetica,Arial,sans-serif !important; 
        padding:20px ;
      }
      .pop-up{
        position:fixed;
        top:0;
        right:0;
        left:0;
        bottom:0;
        background-color:rgb(0 0 0 /0.5);
        z-index:9999999999;
        display:flex;
        justify-content:center ;
        align-items:center ;
        overflow:auto ;
        & > div{
            background-color:transparent;
          width: 100%;
            img{
              width:100%;
              max-width: 1000px;
              height: auto;
              margin:auto;
              display: block;
            }
        }
      }
      .pag-cont{
        padding:5px;
        & > span{
          display:inline-block ;
            padding:5px 10px;
            margin:10px auto;
            border-radius:5px;
            cursor: pointer;
            &.pag-active{
              background-color:${Themes.lightgray};
            }
        }
      }
      table{
        width:100%;
        border-collapse:collapse;
        flex-grow: 1;
        margin:auto;
        tr:nth-child(even) ,thead{background-color: #f2f2f2;}
      thead{
        font-weight: 900;
      }
        td{
            color:${Themes.inputColor};
            border:1px solid ${Themes.niceGrey};
            padding:10px;
            white-space: nowrap;
            word-break: keep-all;
            font-family: 'Nunito Sans','Helvetica Neue',Helvetica,Arial,sans-serif !important; 
           
        }
        svg{
            cursor: pointer;
            margin:5px;
        }
      }
    `
            