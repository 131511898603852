
    import React,{useEffect, useState} from 'react'
import CustomeDropdown from '../CustomeDropdown/CustomeDropdown.component'
import FormBtn from '../FormBtn/FormBtn.component'
    import { StyledCustomEditableTable } from './CustomEditableTable.styled'
    export default function CustomEditableTable({noAction = false,handleInputChange,handleMedDropdownChange, handleAddRow,meds,selectedMeds,theader=[],actions = [],data = []}) {

    const cellBuilder = (i,d,dk)=>{
        const handleMedDropdown = (ind)=>{
             handleMedDropdownChange(i,ind);
        }
        let type = "text";
        switch(dk){
            case "quantity": type = "number";break;
            case "takeTime": type = "text";break;
            default: type = "date"
        }
        switch(dk){
            case "medicine":
                return <CustomeDropdown handleSelect={handleMedDropdown } searchRequired={true} selected={selectedMeds ? selectedMeds[i] ?? 0 : 0}  list={meds.map(m=>m.name)} />
             case "dataOfManufacturePres":
                return <span>{d[dk]}</span>
            case "dateOfExpirePres":
                return <span>{d[dk]}</span>
            case "dosage":
                return  <><input min={1} type="number" required onChange={(e)=>handleInputChange(i,dk,e.target.value)} value={d[dk]} />&nbsp;{selectedMeds ? meds[selectedMeds[i]].name.replace(/^.+[,](.+)$/g ,"$1") ?? "" : ""}  </>
            case "stock": 
                return <span>{d[dk]}</span>

        }
   
       
         return  <input min={1} type={type} required onChange={(e)=>handleInputChange(i,dk,e.target.value)} value={d[dk]} />
    }
    return (
        <StyledCustomEditableTable> 
          <table>
                <thead>
                    {theader.map(th=><td>{th}</td>)}
                    {noAction ? null : <td>Actions</td>}
                </thead>
                <tbody>
                    {data.map((d,i)=>
                    <tr>
                        {Object.keys(d).map(dk=>{
                        return <td>{cellBuilder(i,d,dk)}</td>})}
                        {noAction ? null : <td>{actions.map(a=><a.icon onClick={()=>a.handler(i)}/>)}</td>}
                        
                    </tr>
                    )}
                </tbody>
            </table>
            <FormBtn onClick={handleAddRow} type="button" disabled={false} isFullWidth={false} label="+" />
        </StyledCustomEditableTable> 
    )
    }
            