
    import React, { useState } from 'react'
    import { StyledDashboardList } from './DashboardList.styled'
    import {AccountCircle,ArrowDropDown} from "@mui/icons-material";
    import {useNavigate} from "react-router-dom"
    export default function DashboardList({data = {},index = 0,handleDropdown,handleExpanded}) {
    const navigate = useNavigate();
    const handleRouting = (route)=>{
     // handleDropdown(index);
      handleExpanded(false);
      navigate(route);
    }
    return (
        <StyledDashboardList> 
                               <div onClick={()=>data.route && handleRouting(data.route)}  className={`nav-list ${data.isActive ? "active" : ""}`}>
                   <div onClick={()=>handleDropdown(index)} className='selector'>
                     <data.icon  />
                    <span>{data.title}</span>
                    {data.subList.length > 0 ? <img className='dropdown-icon' src="/assets/down.png" />: null}
                  </div>
                    <div className='options'>
                        
                         {data.subList.map(sl=>
                         <div onClick={()=>handleRouting(sl.route)}><AccountCircle style={{opacity:0}} /><span>{sl.name}</span></div>
                         )}
                    </div>
                   </div>
        </StyledDashboardList> 
    )
    }
            