
    import styled from "styled-components";
    export const StyledFilterAndSearch = styled.header`
    max-width:500px;
    margin-left:auto;
      & >div{
        display:flex;
        gap:10px;
      }
    `
            