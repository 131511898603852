
    import styled from "styled-components";
    export const StyledAddPatient = styled.div`
     display:flex;
    flex-direction:column;
    max-width:1000px;
    margin:auto;
     & > div{
         display:flex;
         flex-wrap:wrap;
         gap:10px;
         & > div{
            flex-grow:1;
            
         }
     }
    `
            