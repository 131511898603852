import React, { useEffect, useState }  from 'react'
import { CustomTable, PageLayout,Toaster } from '../../components'
import { Requests } from '../../services/requests';
import { Edit ,Delete, MedicalInformation} from '@mui/icons-material';
import {useSelector} from "react-redux";
import { useNavigate } from 'react-router-dom';
    import { StyledViewOutofStock } from './ViewOutofStock.styled'
import { List } from '@mui/material';
    export default function ViewOutofStock() {
        const navigate =    useNavigate();
        const user  = useSelector((state) => state.user.user);
        const [loading,setLoading] = useState(true);
        const theader = ["Medicine Name","Generic Name"]
        const [listData,setListData] = useState([]);
        const [pageLoading,setPageLoading] = useState(true);
        const [toastMes,setToastMes] = useState({message:"",isError:false});
    useEffect(()=>{
        (async ()=>{
            console.log("it is in this habbit")
            setPageLoading(true);
            try{
                if(!user.isAdmin){
                   
                    const res = await Requests.getBatchOutOfStockPharm();
                    if(res.data.success){
                        setListData(res.data.data)
                    }
                }else{
                    const res = await Requests.getBatchOutOfStockAdmin();
                    console.log(res.data);
                    if(res.data.success){
                        setListData(res.data.data)
                    }
                }
                setLoading(false);
                setPageLoading(false);
            }catch(e){
                console.log(e);
                setLoading(false)
                setPageLoading(false);
            }
        })();
    },[]);
    useEffect(()=>{
        setTimeout(()=>{
          setToastMes("")
         },5000);
       },[toastMes])
    return (
        <PageLayout  loading={pageLoading} title='Stockout list'>  
        <Toaster message={toastMes.message} isError={toastMes.isError} /> 
        <StyledViewOutofStock> 
            <CustomTable loading={loading} theader={theader} noAction={true}  data={listData.map(l=>{const {id,isHQ,...rest} = l;return rest})}/>
        </StyledViewOutofStock> 
            </PageLayout>  
    )
    }
            