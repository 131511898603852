
    import React from 'react'
    import { StyledFormBtn } from './FormBtn.styled'
    export default function FormBtn({disabled = true,isFullWidth = true,type="submit",onClick=()=>{},label}) {
    return (
        <StyledFormBtn className={disabled ? (isFullWidth ?  "disabled full" : "disabled") : (isFullWidth ?  "full" : "")} onClick={onClick} type={type} disabled={disabled}> 
                    {
            disabled ?
            <img src="/assets/loading btn.svg" alt="" />
            : label
        }
        </StyledFormBtn> 
    )
    }
            