
    import styled from "styled-components";
    import Themes from "../../style/theme";
    export const StyledRegister = styled.div`
    width:100%;
    min-height:100vh;
    overflow:auto;
    position:relative;
    box-sizing:border-box ;
    z-index:1;
    display:flex;
    justify-content:center;
    flex-direction:column ;
    h1{
       box-sizing:border-box;
    }
    & > div{
      height:100%;
      width:100%;
      margin:0 !important;
      max-width:100% !important;
      overflow: auto;
    }
   `
           
            