
    import React, { useState } from 'react'
    import { StyledDashboardLayout } from './DashboardLayout.styled'
    import {DashboardHeader,DashboardSidebar} from "../";
    export default function DashboardLayout({children,navs = []}) {
    const [isExpanded,setIsExpanded] = useState(false);
    const handelIsExpanded = (isExp)=>{
        console.log(isExp,isExpanded,isExp === undefined);
         if(isExp === undefined) setIsExpanded(!isExpanded)
         else setIsExpanded(isExp);
    }
    return (
        <StyledDashboardLayout> 
            <DashboardSidebar navs={navs} handleExpanded={handelIsExpanded} isExpanded={isExpanded} />
            <div className='dashbord-main-contianer'>
               <DashboardHeader handleExpanded={handelIsExpanded} />
                <div className='dashboard-pageview'>
                   {children}
                </div>
               
            </div>
           
        </StyledDashboardLayout> 
    )
    }
            