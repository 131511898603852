import axios from "axios";
const BASE_URL = "https://kenema.kidusabreham.com/api"
axios.defaults.baseURL = BASE_URL;
axios.interceptors.request.use(async (req)=>{
  const aToken =  localStorage.getItem('accessToken') ?? "";
  req.headers.Authorization = `Bearer ${aToken}`;
  let options = {
    method: 'POST',
    headers: {
        'Content-Type':'application/json;charset=utf-8',
        'Authorization':`Bearar ${aToken}`
    },
    body:null
}
 try{
   const res = await fetch(`${BASE_URL}/auth/verify`,options);
   const data = await res.json();
   if(data.success)  return req;
   else{
    if(data.isTokenExpired){
      if(localStorage.getItem("refreshToken")&&localStorage.getItem("refreshToken").trim()!=""){
       options.body = JSON.stringify({
         refreshToken:localStorage.getItem("refreshToken")
       });
     try{
       const res =  await fetch(`${BASE_URL}/auth/token`,options);
       const data = await res.json();
       if(data.success){
        localStorage.setItem('accessToken',data.accessToken);
        req.headers.Authorization = `Bearer ${data.accessToken}`;
        return req;
       }
     }catch(e){
      console.log(e)
     }         
    }
   }
   }
  
 }catch(e){
  if(e.response&&e.response.data)
    if(e.response.data.isTokenExpired){
       if(localStorage.getItem("refreshToken")&&localStorage.getItem("refreshToken").trim()!=""){
        options.body = {
          refreshToken:localStorage.getItem("refreshToken")
        }
      try{
        const res =  await fetch(`${BASE_URL}/auth/token`,options);
        const data = await res.json();
        localStorage.setItem('accessToken',data.accessToken);
        req.headers.Authorization = `Bearer ${data.accessToken}`;
        return req;
      }catch(e){}         
     }
    }
   return {success:false};
 }
  return req;
},(e)=>console.log("faied interceptro"));
axios.interceptors.response.use(function (response) {
  return response;
})
export const Requests = {
    login:async (body)=>  fetch(`${BASE_URL}/auth/login_pharm`,{method: 'POST', headers: {
        'Content-Type':'application/json;charset=utf-8',
    },body:JSON.stringify(body)}),
      getUser:async ()=>  axios.get("/auth/user"),
      changeOldpwd:async (body)=>  axios.post("/auth/chkpwd",body),   
      addBranch:async (body)=>  axios.post("/abranch",body),
      getBranch:async ()=>  axios.get("/abranch"),
      getBranchById:async (id)=>  axios.get(`/abranch/${id}`),
      deleteBranch:async (id)=>  axios.delete(`/abranch/${id}`),
      updateBranch:async (id,body)=>  axios.put(`/abranch/${id}`,body),
      getBranchPharm:async ()=>  axios.get("/batchp/getbranch"),

      addPharmacist:async (body)=>  axios.post("/pharm",body),
      getPharmacist:async ()=>  axios.get("/pharm"),
      getPharmacistById:async (id)=>  axios.get(`/pharm/${id}`),
      deletePharmacist:async (id)=>  axios.delete(`/pharm/${id}`),
      updatePharmacist:async (id,body)=>  axios.put(`/pharm/${id}`,body),

      addMedcine:async (body)=>  axios.post("/medcine",body),
      getBatchOutOfStockAdmin:async ()=>  axios.get(`/medcine/getoutofstock`),
      getBatchExpiredAdmin:async ()=>  axios.get(`/medcine/getexpired`),
      getMedcine:async ()=>  axios.get("/medcine"),
      getMedcineById:async (id)=>  axios.get(`/medcine/byid/${id}`),
      deleteMedcine:async (id)=>  axios.delete(`/medcine/${id}`),
      updateMedcine:async (id,body)=>  axios.put(`/medcine/${id}`,body),
      getMedcinePharm:async ()=>  axios.get("/batchp/getmed"),



      saveBatch:async (body)=>  axios.post("/batch",body),

      getBatchByDate:async (date)=>  axios.get(`/batch/date/${date}`),
      getBatchById:async (id)=>  axios.get(`/batch/byId/${id}`),
      getBatch:async ()=>  axios.get(`/batch/`),
      getBatchPharm:async ()=>  axios.get(`/batchp/`),
      getBatchMed:async ()=>  axios.get(`/batch/getmed`),
      deleteBatch:async (medid)=>  axios.delete(`/batch/${medid}`),
      getBatchOutOfStock:async ()=>  axios.get(`/batch/getoutofstock`),
      getBatchExpired:async ()=>  axios.get(`/batch/getexpired`),
      disposeBatchExpired:async ()=>  axios.get(`/batch/disposeexpired`),
      getBatchOutOfStockPharm:async ()=>  axios.get(`/batchp/getoutofstock`),
      getBatchExpiredPharm:async ()=>  axios.get(`/batchp/getexpired`),
      disposeExpiredPharm:async ()=>  axios.get(`/batchp/disposeexpired`),
      getPending:async ()=>  axios.get(`/batch/getpending`),
      getPendingPharm:async ()=>  axios.get(`/batchp/getpending`),
      acceptPending:async ()=>  axios.post(`/batchp/acceptpending`),
      distMedicine:async ()=>  axios.post(`/batch/distmed`),
      cancelPending:async ()=>  axios.post(`/batch/cancelpending`),


      addPrescription:async (body)=>  axios.post("/pres",body),
      rePrescribe:async (body)=>  axios.post("/pres/represcribe",body),
      getPrescription:async ()=>  axios.get("/pres"),

      getPrescriptionDaily:async (date)=>  axios.post("/pres/report/daily",{date:date}),
      getPrescriptionMonthly:async (date)=>  axios.post("/pres/report/monthly",{date:date}),
      getPrescriptionYearly:async (date)=>  axios.post("/pres/report/yearly",{date:date}),

      getPrescriptionById:async (id)=>  axios.get(`/pres/${id}`),
      deletePrescription:async (id)=>  axios.delete(`/pres/${id}`),
      updatePrescription:async (id,body)=>  axios.put(`/pres/${id}`,body),
     
      getNotify:async ()=>  axios.get("/notify"),
      sendNotify:async (body)=>  axios.post("/notify",body),
      deleteNotify:async (id)=>  axios.delete(`/notify/${id}`),

      addInsuranceUser:async (body)=>  axios.post("/iuser",body),
      getInsuranceUser:async ()=>  axios.get("/iuser"),
      getPendingInsuranceUser:async ()=>  axios.get("/iuser/pending"),
      acceptPendingInsuranceUser:async (body)=>  axios.post("/iuser/acceptpending",body),
      getInsuranceUserById:async (id)=>  axios.get(`/iuser/${id}`),
      deleteInsuranceUser:async (id)=>  axios.delete(`/iuser/${id}`),
      updateInsuranceUser:async (id,body)=>  axios.put(`/iuser/${id}`,body),
      }