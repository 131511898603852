import React, { useEffect, useState }  from 'react'
import { CustomTable,FilterAndSearch, PageLayout,Toaster } from '../../components'
import { Requests } from '../../services/requests';
import { Edit ,Delete} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { StyledListNotify } from './ListNotify.styled'
import useToast from '../../custome_hook/useToast';
    export default function ListNotify() {
        const navigate =    useNavigate();
        const [loading,setLoading] = useState(true);
        const theader = ["Date","Branch","Message"]
        const [listData,setListData] = useState([]);
        const [listDatainit,setListDatainit] = useState([]);
        const [toastMes,setToastMes] = useToast();
        const [pageLoading,setPageLoading] = useState(true);
        const searchBy  = [{key:"branchid",name:"Branch"}];
        const filters = [];
        const handleSearch = (searched)=>{
            setListData(searched)
        }
        const actions = [
            {
            icon:Delete,handler:async (ind)=>{
                const id = listData[ind].id;
                try{    
                    setPageLoading(true);
                    setListData(listData.filter((l,i)=>i!=ind));
                    await Requests.deleteNotify(id);
                   setListDatainit(listData.filter((l,i)=>i!=ind));
                   setToastMes({message:"Deleted Successfully",isError:false});
                   setPageLoading(false);
                }catch(e){
                    setToastMes({message:e.response.data.message,isError:true});
                    console.log(e);
                    setPageLoading(false);
                }
            }
        }
    ];
    useEffect(()=>{
        setListData(listDatainit);
    },[listDatainit])
    useEffect(()=>{
        (async ()=>{
            try{
                setPageLoading(true);
                const res = await Requests.getNotify();
                const res2 = await Requests.getBranch();
                if(res.data.success){
                    setListDatainit([...res.data.data.map(d=>{
                    const branch =  res2.data.data.filter(x =>{
                            return d.branch_id === x._id
                        })[0];
                        return {
                            id:d._id,
                            date: d.date,
                            branchid:branch && branch.name,
                            message:d.message
                    }})])
                }
                setLoading(false);
                setPageLoading(false);
            }catch(e){
                console.log(e);
                setLoading(false);
                setPageLoading(false);
            }
        })();
    },[]);
    useEffect(()=>{
        setTimeout(()=>{
          setToastMes("")
         },5000);
       },[toastMes])
    return (
        <PageLayout loading={pageLoading} title='Notifications'>  
        <Toaster message={toastMes.message} isError={toastMes.isError} />  
        <StyledListNotify> 
        <FilterAndSearch searchHandler={handleSearch} data={listDatainit.map(l=>{const {isHQ,...rest} = l;return rest})} searchBy={searchBy} filters={filters} />
        <CustomTable loading={loading} theader={theader} actions={actions} data={listData.map(l=>{const {id,isHQ,...rest} = l;return rest})}/>
        </StyledListNotify> 
        </PageLayout>
    )
    }
            