
    import styled from "styled-components";
    export const StyledAddInsured = styled.div`
     max-width:1000px;
     margin:auto;
     & > form > div{
        display: grid !important;
        grid-template-columns: repeat(auto-fit,minmax(300px ,1fr));
        justify-content:start;
        justify-items:center;
        align-content:stretch;
        align-items:stretch;
        grid-gap: 10px;
        & > *{
            width:100%;
            max-width:500px ;
        }
     }

    `
            