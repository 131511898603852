let Themes = {
    primaryGreen:"#28a745",
    primaryGreenDark:"#1e7e34",
    niceGreen:"#37a000",
    white:"#FFFFFF",
    fadeBlack:"#212529",
    redLight:"red",
    inputBg:"#ced4da",
    inputColor:"#495057",
    inputOutline:"rgb(0 123 255 / 0.5)",
    niceBlack:"#1c1f22",
    lightgray:"lightgray",
    niceGrey:"#f4f4f5",
    niceRed:"#f13a4b",
    darkGrey:"#70737c",
    veryDarkGrey:"#494c57",
    middleGrey:"#A5A9BA",
    transprentBlack:"rgb(0 0 0 / 0.5)",
    fadeRed:"#f8d7da",
    fontRed:"721c24"
}
export default Themes;