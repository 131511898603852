
    import React, { useEffect, useState } from 'react'
    import { StyledCustomeDropdown } from './CustomeDropdown.styled'
    export default function CustomeDropdown({loading = false,searchRequired=false,error = "",label = "",required = false,selected = -1,placeholder = "",list = [],handleSelect = (i)=>{}}) {
    const [isDroped,setIsDroped] = useState(false);
    const [searchKey,setSearchKey] = useState("");
    const onClickOutside = (e) => {
        if(e.target)
        if(e.target.className.includes)
        if (!e.target.className.includes("click-protected")) {
           setIsDroped(false)
        }
      };
useEffect(()=>{
  window.addEventListener("click", onClickOutside);
  return ()=>{
    window.removeEventListener("click", onClickOutside);
  }
},[]);
    return (
        <StyledCustomeDropdown> 
         <div>
            <span><h5>{label}</h5>{required ? <span>*</span> : <span>&nbsp;</span>}</span>
             <div className='select-cont click-protected'>
                <span onClick={()=>(!loading) ? setIsDroped(!isDroped) : setIsDroped(false)} className='selected click-protected'>{selected != null ?  list[selected] : placeholder} <img className={isDroped ? 'dropdown-icon droped' : "dropdown-icon"} src="/assets/down.png" /></span>
                {isDroped ? <div className='dropdown-cont'>
                    {searchRequired ? <input onChange={(e)=>setSearchKey(e.target.value)}className='click-protected' placeholder="Search.." /> : null}
                    <ul>
                        {
                            list.map((l,i)=>{
                                 if(l.toUpperCase().match(searchKey.toUpperCase()))
                                 if(selected!==null&&i==selected)
                                    return <li onClick={()=>{setIsDroped(false);setSearchKey("")}} key={i} className='active'>{l}</li>
                                else return <li onClick={()=>{
                                    handleSelect(i);
                                    setIsDroped(false);
                                    setSearchKey("");
                                }} key={i}>{l}</li>
                               return null;
                            })
                        }

                    </ul>
                </div> : null}
             </div>
            </div>
           
     <span>{error}</span>
        </StyledCustomeDropdown> 
    )
    }
            